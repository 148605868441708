import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "react-phone-input-2/lib/bootstrap.css";
import { extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "@chakra-ui/pro-theme";

import "@fontsource/work-sans/300.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/900.css";

import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as materialCreateTheme,
  THEME_ID,
} from "@mui/material/styles";
import { DialogProvider } from "./Common/Dialog";

const materialTheme = materialCreateTheme({
  zIndex: {
    modal: 1800,
  },
});

const theme = extendTheme({
  fonts: {
    heading: `'Work Sans', sans-serif`,
    body: `'Work Sans', sans-serif`,
  },
  colors: {
    kydRed: {
      200: '#e53e3e',
    }
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
      variants: {
        kydDark: {
          bg: "black",
          color: "white",
          _hover: {
            bg: "gray.800",
            _disabled: {
              bg: "black",
            },
          },
        },
      },
    },
  },
  proTheme,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.Fragment>
    <ChakraProvider theme={theme}>
      <MaterialThemeProvider theme={{ [THEME_ID]: materialTheme }}>
        <DialogProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DialogProvider>
      </MaterialThemeProvider>
    </ChakraProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
