import React, { useCallback, useEffect, useState } from "react";
import actions from "../actions";
import {
  Box,
  Text,
  Stack,
  Image,
  HStack,
  VStack,
  Button,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { KYDEventSummary } from "@common/interfaces/KYDEvent";
import Div100vh from "react-div-100vh";

import logoFooter from "../lightsmall.png";
import logoFooterDark from "../darksmall.png";

import { analytics } from "../utils";
import "react-phone-input-2/lib/bootstrap.css";
import { SocialIcon } from "react-social-icons";

import { KYDFeed, KYDFeedItem } from "@common/interfaces/KYDOrganization";
import lightsmall from "../lightsmall.png";
import { useDialog } from "../Common/Dialog";
import Event from "./Event";

function KYDFeedComponent() {
  const [feed, setFeed] = useState<KYDFeed | null>(null);
  const [_loading, setLoading] = useState<any>({});
  const [displayAllMode, setDisplayAllMode] = useState<any>({});

  const spinnerColor = useBreakpointValue({ base: "white", md: "black" });

  const isDesktop = useBreakpointValue({ base: false, md: true });

  const updateLoadingState = useCallback((key: "feed", value: boolean) => {
    setLoading((l: any) => {
      return { ...l, [key]: value };
    });
  }, []);

  const updateDisplayAllMode = useCallback(
    (key: "kyd_events" | "news" | "music", value: boolean) => {
      setDisplayAllMode((l: any) => {
        return { ...l, [key]: value };
      });
    },
    []
  );

  useEffect(() => {
    const fetchKYDEvents = async () => {
      updateLoadingState("feed", true);
      try {
        const _ = await actions.USER.fetchBio();
        analytics.t("event: fetched all upcoming events");

        if (_.organization) {
          document.title = _.organization.name;
          /*if (_.organization.pixels) {
            setupPixels(_.organization.pixels);
          }*/
        }
        setFeed(_);

        analytics.t("pageview", {
          type: "bio",
        });
      } catch (err: any) {
        //TODO: show error
        analytics.t("error: failed to load upcoming events", {
          message: err && err.message,
        });
      }
      updateLoadingState("feed", false);
    };

    fetchKYDEvents();
  }, [updateLoadingState]);

  const renderEvent = (kyd_event: KYDEventSummary, index: number) => {
    return (
      <Event
        displayAllMode={displayAllMode}
        feed={feed}
        kyd_event={kyd_event}
        index={index}
      />
    );
  };

  const renderFeedItem = (item: KYDFeedItem, index: number) => {
    let borderBottomWidth = "1px";
    if (
      item.type === "music" &&
      (index === (feed?.music_items.length || 0) - 1 ||
        (index === 2 && !displayAllMode.music))
    ) {
      borderBottomWidth = "0px";
    } else if (
      item.type === "news" &&
      (index === (feed?.news_items.length || 0) - 1 ||
        (index === 2 && !displayAllMode.news))
    ) {
      borderBottomWidth = "0px";
    }

    return (
      <a
        key={item.id}
        style={{ width: "100%" }}
        href={item.link}
        target="_blank"
        rel="noreferrer"
      >
        <HStack
          p={3}
          w="100%"
          minH="100px"
          borderBottom={borderBottomWidth}
          borderColor={"gray.300"}
          justify={"space-between"}
          spacing={3}
        >
          <HStack>
            <Image rounded={"md"} src={item.image} maxW={"100px"} />
            <Stack spacing={0}>
              <Text fontWeight="semibold">{item.title}</Text>
              {item.description ? (
                <Text fontSize={"sm"}>{item.description}</Text>
              ) : null}
            </Stack>
          </HStack>
          <Stack
            display={{ base: "none", md: "flex" }}
            justify={"center"}
            h="100%"
            spacing={0}
          >
            <VStack
              borderWidth={"1px"}
              borderColor={"black"}
              rounded={"md"}
              px={4}
              py={2}
              minW={"130px"}
              fontWeight={"medium"}
              _hover={{ bg: "gray.100" }}
              color="black"
            >
              <Text>
                {item.type === "music" ? "Listen Now" : "Read Article"}
              </Text>
            </VStack>
          </Stack>
        </HStack>
      </a>
    );
  };

  const renderDesktopHeader = () => (
    <Box w="100%" px={4}>
      <Box
        w="100%"
        color={"white"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"flex-end"}
      >
        <Stack py={3} spacing={3}>
          <Stack spacing={-2}>
            <Text fontSize={"5xl"} fontWeight={"bold"}>
              {feed?.organization?.name}
            </Text>
            <HStack>
              <Text>{feed?.organization?.display_verified_fans}</Text>
            </HStack>
          </Stack>

          <HStack>
            {feed?.organization.socials.twitter ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.twitter.link}
              />
            ) : null}
            {feed?.organization.socials.instagram ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.instagram.link}
              />
            ) : null}
            {feed?.organization.socials.spotify ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.spotify.link}
              />
            ) : null}
            {feed?.organization.socials.applemusic ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.applemusic.link}
              />
            ) : null}
            {feed?.organization.socials.soundcloud ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.soundcloud.link}
              />
            ) : null}
            {feed?.organization.socials.youtube ? (
              <SocialIcon
                style={{ width: "40px", height: "40px" }}
                fgColor="#fff"
                url={feed?.organization.socials.youtube.link}
              />
            ) : null}
          </HStack>
        </Stack>
        {feed?.organization.feed_image ? (
          <Box position={"relative"} zIndex={"100"} mb={"-40px"}>
            <Image
              rounded={"xl"}
              src={feed?.organization.feed_image}
              maxW={"300px"}
            />
            {feed ? (
              <Box
                position={"absolute"}
                bg="black"
                right="0"
                bottom="0"
                roundedBottomRight={"xl"}
                roundedTopLeft={"xl"}
                px={5}
                py={3}
              >
                <Image src={lightsmall} maxW={"50px"} />
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );

  const renderMobileHeader = () => (
    <Stack w="100%" spacing={0} color={"white"}>
      <VStack w="100%">
        <Box position={"relative"}>
          <Image
            rounded={"xl"}
            src={feed?.organization.feed_image}
            maxW={"200px"}
          />
          {feed ? (
            <Box
              position={"absolute"}
              bg="black"
              right="0"
              bottom="0"
              roundedBottomRight={"xl"}
              roundedTopLeft={"2xl"}
              px={3}
              py={1}
            >
              <Image src={lightsmall} maxW={"40px"} />
            </Box>
          ) : null}
        </Box>
      </VStack>
      <Stack py={3} px={3} spacing={3}>
        <Stack spacing={-2}>
          <Text fontSize={"4xl"} fontWeight={"bold"}>
            {feed?.organization?.name}
          </Text>
          <HStack fontSize={"sm"}>
            <Text>{feed?.organization.display_verified_fans}</Text>
          </HStack>
        </Stack>

        <HStack>
          {feed?.organization.socials.twitter ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.twitter.link}
            />
          ) : null}
          {feed?.organization.socials.instagram ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.instagram.link}
            />
          ) : null}
          {feed?.organization.socials.spotify ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.spotify.link}
            />
          ) : null}
          {feed?.organization.socials.applemusic ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.applemusic.link}
            />
          ) : null}
          {feed?.organization.socials.soundcloud ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.soundcloud.link}
            />
          ) : null}
          {feed?.organization.socials.youtube ? (
            <SocialIcon
              style={{ width: "40px", height: "40px" }}
              fgColor="#fff"
              url={feed?.organization.socials.youtube.link}
            />
          ) : null}
        </HStack>
      </Stack>
    </Stack>
  );

  return (
    <Div100vh>
      <VStack
        overflow={"scroll"}
        w="100vw"
        h="100%"
        bgGradient="linear-gradient(322.9deg, #8B8B8B -32.49%, #000000 30.1%, #332E38 97.31%)"
        backgroundSize={"cover"}
        justifyContent={"space-between"}
      >
        <Stack spacing={0} w="100%" maxW={["100%", null, "3xl"]}>
          <Box zIndex={1000} w="100%" p={0}>
            <VStack zIndex={1001} w="100%">
              <HStack
                px={5}
                justify={"space-between"}
                w="100%"
                h={{ base: "100px", md: "100px" }}
              >
                <Image p={2} src={feed?.organization?.logo_url} maxW="100px" />
              </HStack>
            </VStack>
          </Box>
          {isDesktop ? renderDesktopHeader() : null}
          {!isDesktop ? renderMobileHeader() : null}

          <VStack pb={[0, null, 10]} spacing={{ base: 0, md: 0 }} w="100%">
            {!feed ? (
              <VStack
                roundedTop={{ base: "none", md: "lg" }}
                bg={{ base: "none", md: "white" }}
                py={4}
                w="100%"
              >
                <Spinner color={spinnerColor} />
              </VStack>
            ) : null}
            {feed && feed.kyd_events.length > 0 ? (
              <Box
                w="100%"
                bg={"white"}
                roundedTop={{ base: "none", md: "lg" }}
                roundedBottom={"none"}
                borderColor="gray.200"
                shadow={"lg"}
                px={[3, null, 5]}
                pt={[3, "50px"]}
              >
                <Text fontWeight={"medium"} fontSize={"2xl"}>
                  Upcoming Events
                </Text>
                <VStack spacing={0}>
                  {displayAllMode.kyd_events
                    ? feed.kyd_events.map(renderEvent)
                    : feed.kyd_events.slice(0, 3).map(renderEvent)}
                </VStack>
                {!displayAllMode.kyd_events && feed.kyd_events.length > 3 ? (
                  <VStack w="100%" my={4}>
                    <Button
                      bg="black"
                      color="white"
                      maxW={"300px"}
                      h="50px"
                      onClick={() => updateDisplayAllMode("kyd_events", true)}
                    >
                      See All Upcoming Events
                    </Button>
                  </VStack>
                ) : null}
              </Box>
            ) : null}

            {feed && feed.news_items.length > 0 ? (
              <VStack
                w="100%"
                bg={"white"}
                roundedBottom={"none"}
                borderColor="gray.200"
                shadow={"lg"}
                px={[3, null, 5]}
                pt={[3, 5]}
              >
                <Text
                  textAlign={"center"}
                  borderTop="1px"
                  borderBottom={"1px"}
                  w="100%"
                  fontSize={"md"}
                  fontWeight={"medium"}
                  py={2}
                >
                  In The News
                </Text>
                <VStack w="100%" spacing={0}>
                  {displayAllMode.news
                    ? feed.news_items.map(renderFeedItem)
                    : feed.news_items.slice(0, 3).map(renderFeedItem)}
                </VStack>
                {!displayAllMode.news && feed.news_items.length > 3 ? (
                  <VStack w="100%" my={4}>
                    <Button
                      variant="kydDark"
                      maxW={"300px"}
                      h="50px"
                      onClick={() => updateDisplayAllMode("news", true)}
                    >
                      See All News
                    </Button>
                  </VStack>
                ) : null}
              </VStack>
            ) : null}

            {feed && feed.music_items.length > 0 ? (
              <VStack
                w="100%"
                bg={"white"}
                roundedBottom={"none"}
                borderColor="gray.200"
                shadow={"lg"}
                px={[3, null, 5]}
                py={[3, 5]}
              >
                <Text
                  textAlign={"center"}
                  borderTop="1px"
                  borderBottom={"1px"}
                  w="100%"
                  fontSize={"md"}
                  fontWeight={"medium"}
                  py={2}
                >
                  Music
                </Text>
                <VStack w="100%" spacing={0}>
                  {displayAllMode.music
                    ? feed.music_items.map(renderFeedItem)
                    : feed.music_items.slice(0, 3).map(renderFeedItem)}
                </VStack>
                {!displayAllMode.music && feed.music_items.length > 3 ? (
                  <VStack w="100%" my={4}>
                    <Button
                      variant="kydDark"
                      maxW={"300px"}
                      h="50px"
                      onClick={() => updateDisplayAllMode("music", true)}
                    >
                      See All Music
                    </Button>
                  </VStack>
                ) : null}
              </VStack>
            ) : null}
            <Box
              w="100%"
              rounded={{ base: "none", md: "lg" }}
              borderColor="gray.200"
              shadow={"lg"}
            >
              <Stack spacing={0}>
                <HStack
                  display={{ base: "none", md: "flex" }}
                  borderTop={"1px"}
                  borderTopColor="gray.200"
                  justify={"space-between"}
                  p={3}
                  roundedBottom={"lg"}
                  bg="gray.50"
                >
                  <HStack spacing={4}>
                    <Image maxW={"60px"} src={logoFooterDark} />
                    <Text fontSize={"xs"} textDecor={"underline"}>
                      <a
                        href="https://www.iubenda.com/terms-and-conditions/22517592"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Terms Of Service
                      </a>
                    </Text>
                    <Text fontSize={"xs"} textDecor={"underline"}>
                      <a
                        href="https://www.iubenda.com/privacy-policy/22517592"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Text>
                    <Text fontSize={"xs"} textDecor={"underline"}>
                      <a
                        href="https://kydlabs.typeform.com/s/requestaccess"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Create Your Own Page
                      </a>
                    </Text>
                  </HStack>
                  <Stack spacing={0}>
                    <Text textAlign={"right"} color="gray.600" fontSize={"xs"}>
                      © {new Date().getFullYear()} KYD Labs Inc.
                    </Text>
                    <Text textAlign={"right"} color="gray.400" fontSize={"xs"}>
                      v{process.env.REACT_APP_VERSION}
                    </Text>
                  </Stack>
                </HStack>
              </Stack>
            </Box>
          </VStack>
        </Stack>

        <VStack
          display={{ base: "flex", md: "none" }}
          py={4}
          bg="transparent"
          w="100%"
          color="white"
        >
          <VStack pb={2} spacing={1}>
            <Image maxW={"75px"} src={logoFooter} />
            <HStack>
              <Text fontSize={"xs"} textDecor={"underline"}>
                <a
                  href="https://www.iubenda.com/terms-and-conditions/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms Of Service
                </a>
              </Text>
              <Text fontSize={"xs"} textDecor={"underline"}>
                <a
                  href="https://www.iubenda.com/privacy-policy/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </Text>
            </HStack>
            <Text fontSize={"xs"} textDecor={"underline"}>
              <a
                href="https://kydlabs.typeform.com/s/requestaccess"
                target={"_blank"}
                rel="noreferrer"
              >
                Create Your Own Page
              </a>
            </Text>
            <HStack>
              <Text color="gray.200" fontSize={"xs"}>
                © {new Date().getFullYear()} KYD Labs Inc.
              </Text>
              <Text color="gray.400" fontSize={"xs"}>
                v{process.env.REACT_APP_VERSION}
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </Div100vh>
  );
}

export default KYDFeedComponent;
