import React, { useEffect, useState } from "react";
import * as actions from "../actions";
import {
  Icon,
  Text,
  Image,
  Stack,
  HStack,
  IconButton,
  Button,
  VStack,
  Collapse,
  useClipboard,
  useToast,
  Divider,
  Box,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { KYDTicketType as ITicketType } from "@common/interfaces/KYDTicket";
import { Select } from "chakra-react-select";
import { useCollapse } from "@collapsed/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { FaChevronCircleDown, FaUser } from "react-icons/fa";
import { KYDEvent } from "@common/interfaces/KYDEvent";
import { Wallet } from "@common/interfaces/KYDUser";
import { analytics, showSuccessToast } from "../utils";
import {
  KYDCart,
  KYDCartLineItemV2,
  KYDCartLineItem,
  KYDCartV2,
} from "@common/interfaces/KYDCart";

interface KYDTicketTypesProps {
  kydEvent: KYDEvent;
  cart: KYDCart | KYDCartV2 | null;
  ticketTypes: ITicketType[];
  wallet: Wallet | null;
  quantities: any;
  updateQuantity: (
    ticket_type: ITicketType,
    action: string,
    value?: number
  ) => void;
}

interface KYDTicketTypeCellProps {
  ticketType: ITicketType;
  kydEvent: KYDEvent;
  maxLimitReached: boolean;
  shareLink?: string;
  onCopy: () => void;
  updateQuantity: (
    ticket_type: ITicketType,
    action: string,
    value?: number
  ) => void;
}

function TicketMobileTypeCell(props: KYDTicketTypeCellProps) {
  const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
    defaultExpanded: false,
    collapsedHeight: 20,
  });
  const hasPaidTicketTypes =
    props.kydEvent.ticket_types.filter((tt) => !tt.free).length > 0;
  const toast = useToast();

  return (
    <Stack
      px={2}
      py={3}
      color={"white"}
      w="100%"
      borderWidth={"1px"}
      borderColor={"white"}
      rounded={"lg"}
      bg="black"
      align={"center"}
    >
      <HStack w="100%" spacing={0} justify={"space-between"}>
        <Stack
          onClick={() => setExpanded(!isExpanded)}
          w={"60%"}
          whiteSpace={"pre-wrap"}
          spacing={-1}
        >
          <Text fontSize={"md"} fontWeight={"bold"}>
            {props.ticketType.name}
          </Text>
          {!props.maxLimitReached && props.ticketType.description ? (
            <Text
              {...getCollapseProps()}
              lineHeight={5}
              fontSize="xs"
              minH={"20px"}
            >
              {props.ticketType.description}
            </Text>
          ) : null}
          {props.ticketType.description &&
            props.ticketType.description.length > 30 &&
            !props.maxLimitReached && (
              <Text fontSize={"xs"} fontWeight={"bold"}>
                see {isExpanded ? "less" : "more"}
              </Text>
            )}
        </Stack>
        <Stack spacing={-1} w={"20%"}>
          {(hasPaidTicketTypes || props.kydEvent.display_mode === "normal") &&
          !props.ticketType.sold_out ? (
            <Text fontSize={"md"} fontWeight={"bold"}>
              {props.ticketType.free ? "FREE" : props.ticketType.display_price}
            </Text>
          ) : null}
          {!props.ticketType.free &&
          props.kydEvent.display_options?.price_mode === "exclusive" ? (
            <Text fontSize={"xs"} color="gray.400">
              {props.ticketType.display_fees_total_price} fee
            </Text>
          ) : null}
          {!props.ticketType.free &&
          props.kydEvent.display_options?.show_currency &&
          !props.ticketType.sold_out ? (
            <Text fontSize={"xs"}>{props.kydEvent.currency}</Text>
          ) : null}
          {props.kydEvent.display_mode === "normal" &&
          props.kydEvent.display_options?.price_mode === "inclusive" &&
          !props.ticketType.sold_out ? (
            <Stack spacing={-3}>
              <Text color="gray.400" fontSize={"10px"}>
                All-In price.
              </Text>
              <Text color="gray.400" fontSize={"10px"}>
                No surprises.
              </Text>
            </Stack>
          ) : null}
        </Stack>

        {props.ticketType.sold_out ? (
          <Text color="gray.400" fontWeight={"bold"}>
            Sold Out
          </Text>
        ) : props.kydEvent.status !== "PREVIEW" ? (
          <Stack
            display={props.maxLimitReached ? "none" : "inherit"}
            spacing={-2.5}
            color={"white"}
            minW={"61px"}
          >
            <Select
              isSearchable={false}
              onChange={(singleValue) =>
                props.updateQuantity(
                  props.ticketType,
                  "set",
                  singleValue!.value
                )
              }
              menuPlacement="auto"
              placeholder={null}
              defaultValue={{ value: 0, label: "0" }}
              menuPortalTarget={document.body}
              chakraStyles={{
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  background: "none",
                  w: "20px",
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  p: "0px",
                  w: "15px",
                  justifyContent: "center",
                }),
                valueContainer: (provided, state) => ({
                  ...provided,

                  justifyContent: "center",
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  maxW: "51px",
                  fontSize: "3xl",
                  fontWeight: "bold",
                  textAlign: "center",
                  overflow: "visible",
                }),
                container: (provided, state) => ({
                  ...provided,
                  maxW: "60px",
                  justifyContent: "flex-end",
                }),
                control: (provided, state) => ({
                  ...provided,
                  border: "none",
                  caretColor: "transparent",
                }),
                indicatorSeparator: (provided, state) => ({
                  ...provided,
                  display: "none",
                }),
              }}
              options={new Array(
                (props.ticketType.limit > props.ticketType.remaining
                  ? props.ticketType.remaining
                  : props.ticketType.limit) + 1
              )
                .fill(0)
                .map((i, index) => {
                  return { value: index, label: index.toString() };
                })}
            ></Select>
            <Text pl={"10px"} fontSize={"xs"} color="gray.400">
              Qty
            </Text>
          </Stack>
        ) : null}
      </HStack>
      {props.maxLimitReached && props.shareLink ? (
        <Stack w="100%" align={"right"} spacing={1}>
          <Stack w="100%" spacing={0}>
            <Text
              lineHeight={"shorter"}
              fontWeight={"medium"}
              fontSize={"sm"}
              color={"gray.300"}
            >
              Max limit reached.
            </Text>
            <Text lineHeight={"shorter"} fontSize={"sm"} color={"gray.300"}>
              Want to share with a friend?
            </Text>
          </Stack>

          <HStack w="100%">
            <Text
              fontSize={"sm"}
              fontWeight={"bold"}
              borderColor={"gray.300"}
              onClick={async () => {
                analytics.t("event: copied share link", {
                  location: "tickettypes",
                });
                if (window && window.navigator && window.navigator.share) {
                  try {
                    await window.navigator.share({
                      title: props.kydEvent.name,
                      url: props.shareLink,
                    });
                    console.log("Data was shared successfully");
                  } catch (err) {
                    //@ts-ignore
                    console.error("Share failed:", err.message);
                  }
                } else {
                  showSuccessToast(toast, "✅ Link copied!");
                  props.onCopy();
                }
              }}
            >
              {props.shareLink?.split("://").pop()}
            </Text>
            <Text
              borderWidth={"1px"}
              borderColor="white"
              fontSize={"sm"}
              px={2}
              rounded={"md"}
              color="white"
              fontWeight={"bold"}
              cursor={"pointer"}
              onClick={async () => {
                analytics.t("event: copied share link", {
                  location: "tickettypes",
                });
                if (window && window.navigator && window.navigator.share) {
                  try {
                    await window.navigator.share({
                      title: props.kydEvent.name,
                      url: props.shareLink,
                    });
                  } catch (err) {
                    //@ts-ignore
                    console.error("Share failed:", err.message);
                  }
                } else {
                  showSuccessToast(toast, "✅ Link copied!");
                  props.onCopy();
                }
              }}
            >
              Share
            </Text>
          </HStack>
        </Stack>
      ) : null}
    </Stack>
  );
}

function TicketTypeDescriptionDesktop(props: any) {
  const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
    defaultExpanded: false,
    collapsedHeight: 20,
  });

  return (
    <Box onClick={() => setExpanded(!isExpanded)}>
      {!props.maxLimitReached && props.description ? (
        <Text
          {...getCollapseProps()}
          lineHeight={5}
          fontSize="xs"
          minH={"20px"}
        >
          {props.description}
        </Text>
      ) : null}
      {props.description &&
        props.description.length > 30 &&
        !props.maxLimitReached && (
          <Text cursor="pointer" fontSize={"xs"} fontWeight={"bold"}>
            see {isExpanded ? "less" : "more"}
          </Text>
        )}
    </Box>
  );
}

function KYDTicketTypes(props: KYDTicketTypesProps) {
  const { onCopy } = useClipboard(props.wallet?.share_link || "");
  const toast = useToast();
  const hasPaidTicketTypes =
    props.kydEvent.ticket_types.filter((tt) => !tt.free).length > 0;
  return (
    <Stack w="100%">
      <TableContainer w="100%">
        <Table
          whiteSpace={{ base: "inherit", md: "normal" }}
          size={"lg"}
          variant={{ base: "unstlyed", md: "simple" }}
        >
          <Thead>
            <Tr>
              <Th
                px={2}
                display={{ base: "none", md: "table-cell" }}
                textAlign={"left"}
              >
                Ticket Type
              </Th>
              {hasPaidTicketTypes ||
              props.kydEvent.display_mode === "normal" ? (
                <Th
                  px={2}
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"right"}
                >
                  Price
                </Th>
              ) : null}

              {props.kydEvent.display_options?.price_mode !== "inclusive" &&
              (hasPaidTicketTypes ||
                props.kydEvent.display_mode === "normal") ? (
                <Th
                  px={2}
                  display={{ base: "none", md: "table-cell" }}
                  textAlign={"right"}
                >
                  Fees
                </Th>
              ) : null}

              <Th
                display={{ base: "none", md: "table-cell" }}
                textAlign={"right"}
                w={"100px"}
              >
                Quantity
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.ticketTypes.map((tt) => {
              let maxLimitReached = false;
              let tickets = 0;
              if (props.wallet) {
                tickets += props.wallet.tickets.filter(
                  (ticket) => ticket.ticket_type_id === tt.id
                ).length;
              }

              if (props.cart && props.cart.version === "v1") {
                tickets += (props.cart as KYDCart).line_items.filter((li) => {
                  return (
                    li.type === "TICKET" &&
                    (li as KYDCartLineItem).ticket_type_id === tt.id
                  );
                }).length;
              } else if (props.cart && props.cart.version === "v2") {
                tickets += (props.cart as KYDCartV2).line_items.filter((li) => {
                  return (li as KYDCartLineItemV2).entity_type_id === tt.id;
                }).length;
              }

              maxLimitReached = tickets >= tt.limit;

              return (
                <Tr key={tt.id}>
                  <Td
                    px={2}
                    style={{ wordWrap: "break-word" }}
                    display={{ base: "none", md: "table-cell" }}
                  >
                    <Stack
                      whiteSpace={"pre-wrap"}
                      spacing={1}
                      textAlign={"left"}
                      w="100%"
                    >
                      <Text fontWeight={"bold"} fontSize="lg">
                        {tt.name}
                      </Text>

                      <TicketTypeDescriptionDesktop
                        maxLimitReached={maxLimitReached}
                        description={tt.description}
                      />
                    </Stack>
                  </Td>
                  {hasPaidTicketTypes ||
                  props.kydEvent.display_mode === "normal" ? (
                    <Td
                      px={2}
                      maxW={
                        props.kydEvent.display_options?.show_currency
                          ? "200px"
                          : "150px"
                      }
                      minW={"120px"}
                      textAlign={"right"}
                      display={{ base: "none", md: "table-cell" }}
                    >
                      {tt.sold_out ? (
                        <Text></Text>
                      ) : (
                        <Stack spacing={0}>
                          <HStack spacing={1} justify={"right"}>
                            <Text>{tt.free ? "FREE" : tt.display_price}</Text>
                            <Text fontSize={"xs"}>
                              {props.kydEvent.display_options?.show_currency
                                ? props.kydEvent.currency
                                : ""}
                            </Text>
                          </HStack>
                          {props.kydEvent.display_options?.price_mode ===
                          "inclusive" ? (
                            <Stack spacing={-2}>
                              <Text color="gray.600" fontSize={"xs"}>
                                All-In price.
                              </Text>
                              <Text color="gray.600" fontSize={"xs"}>
                                No surprises.
                              </Text>
                            </Stack>
                          ) : null}
                        </Stack>
                      )}
                    </Td>
                  ) : null}
                  {props.kydEvent.display_options?.price_mode !== "inclusive" &&
                  (hasPaidTicketTypes ||
                    props.kydEvent.display_mode === "normal") ? (
                    <Td
                      px={2}
                      maxW={"120px"}
                      textAlign={"right"}
                      display={{ base: "none", md: "table-cell" }}
                    >
                      {tt.free ? "FREE" : tt.display_fees_total_price}
                    </Td>
                  ) : null}

                  <Td
                    textAlign={
                      maxLimitReached
                        ? "left"
                        : tt.sold_out
                        ? "right"
                        : "center"
                    }
                    display={{ base: "none", md: "table-cell" }}
                    w={maxLimitReached ? "250px" : "100px"}
                    p={maxLimitReached ? 2 : 5}
                  >
                    {maxLimitReached && props.wallet?.share_link ? (
                      <Stack align={"right"} spacing={1}>
                        <Stack spacing={0}>
                          <Text
                            lineHeight={"shorter"}
                            fontWeight={"medium"}
                            fontSize={"sm"}
                            color={"gray.500"}
                          >
                            Max limit reached.
                          </Text>
                          <Text
                            lineHeight={"shorter"}
                            fontSize={"sm"}
                            color={"gray.500"}
                          >
                            Want to share with a friend?
                          </Text>
                        </Stack>

                        <HStack>
                          <Text
                            w="125px"
                            fontSize={"xs"}
                            fontWeight={"bold"}
                            borderBottom={"1px"}
                            borderColor={"gray.300"}
                          >
                            {props.wallet?.share_link?.split("://").pop()}
                          </Text>
                          <Text
                            fontSize={"xs"}
                            color="blue.500"
                            fontWeight={"bold"}
                            cursor={"pointer"}
                            onClick={() => {
                              analytics.t("event: copied share link", {
                                location: "tickettypes",
                              });
                              showSuccessToast(toast, "✅ Link copied!");
                              onCopy();
                            }}
                          >
                            COPY LINK
                          </Text>
                        </HStack>
                      </Stack>
                    ) : tt.sold_out ? (
                      <Text color="gray.400" fontWeight={"bold"}>
                        Sold Out
                      </Text>
                    ) : props.kydEvent.status !== "PREVIEW" ? (
                      <VStack>
                        <HStack w="100%" justify={"flex-end"}>
                          <IconButton
                            onClick={() => props.updateQuantity(tt, "sub")}
                            aria-label="Subtract"
                            icon={<MinusIcon />}
                          />
                          <Text
                            fontWeight={"bold"}
                            textAlign={"center"}
                            minW={"20px"}
                          >
                            {props.quantities[tt.id] || 0}
                          </Text>
                          <IconButton
                            onClick={() => props.updateQuantity(tt, "add")}
                            aria-label="Add"
                            icon={<AddIcon />}
                          />
                        </HStack>
                        {/* {tt.remaining <= tt.limit ? (
                          <Text
                            w="100%"
                            textAlign={"right"}
                            fontWeight={"medium"}
                            fontSize={"xs"}
                            color="red.500"
                          >{`${tt.remaining} remaining`}</Text>
                        ) : null} */}
                      </VStack>
                    ) : (
                      <Text textColor={"gray.500"}>PREVIEW</Text>
                    )}
                  </Td>
                  <Td
                    px={0}
                    py={2}
                    display={{ base: "table-cell", md: "none" }}
                  >
                    <TicketMobileTypeCell
                      ticketType={tt}
                      maxLimitReached={maxLimitReached}
                      shareLink={props.wallet?.share_link}
                      onCopy={onCopy}
                      kydEvent={props.kydEvent}
                      updateQuantity={props.updateQuantity}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default KYDTicketTypes;
