import { useCallback, useEffect, useState } from "react";
import actions from "../actions";
import {
  Text,
  Stack,
  HStack,
  Spinner,
  VStack,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  IconButton,
  useBreakpointValue,
  Image,
  Input,
  useToast,
  useClipboard,
} from "@chakra-ui/react";
import { Wallet } from "@common/interfaces/KYDUser";
import {
  analytics,
  ModalType,
  getRandomString,
  PIXELS,
  showSuccessToast,
} from "../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber, { validatePhoneNumberLength } from "libphonenumber-js";
import OtpInput from "react-otp-input";
import * as AWSAmplify from "aws-amplify";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { QRCodeSVG } from "qrcode.react";
import logoFooterDark from "../darksmall.png";
import { useDialog } from "../Common/Dialog";
import { useErrors } from "../hooks/useErrors";

const { Auth } = AWSAmplify;

function KYDModal({
  modalType,
  setModalType,
  wallet,
  setWallet,
  content,
  setContent,
}: {
  modalType: ModalType;
  setModalType: (type: ModalType, content?: string) => void;
  wallet: Wallet | null;
  setWallet: (wallet: Wallet | null) => void;
  content: string | null;
  setContent: (content: string | null) => void;
}) {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [smsCode, setSMSCode] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>({});
  const [draftTransfer, setDraftTransfer] = useState<any>({
    id: "draft",
    ticket_types: {},
    total: 0,
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { pathname } = useLocation();
  const [eventId, setEventId] = useState("");
  const toast = useToast();
  const dialog = useDialog();
  const shareCTA = useBreakpointValue({ base: "Share", md: "COPY LINK" });

  const { onCopy } = useClipboard(wallet?.share_link || "");
  const qrCodeSize = useBreakpointValue({
    base: 220,
    md: 300,
  });

  const updateLoadingState = useCallback(
    (
      key:
        | "auth"
        | "wallet"
        | "cart"
        | "wallet_silent"
        | "validating_phone"
        | "invite"
        | "transfer",
      value: boolean
    ) => {
      setLoading((l: any) => {
        return { ...l, [key]: value };
      });
    },
    []
  );

  const onUpdateTicketTypeQuantity = (
    ticket_type_id: string,
    action: string
  ) => {
    analytics.t("transfer: update quantity", {
      action,
      ticket_type: ticket_type_id,
    });
    if (!draftTransfer.ticket_types) {
      draftTransfer.ticket_types = {};
    }

    const max_quantity =
      wallet?.summary_items.find((s) => s.ticket_type_id === ticket_type_id)
        ?.quantity || 0;
    console.log("Max Quantuty: ", max_quantity, ticket_type_id);
    const current_quantity = draftTransfer.ticket_types[ticket_type_id] || 0;
    if (action === "add" && current_quantity < max_quantity) {
      draftTransfer.ticket_types[ticket_type_id] = current_quantity + 1;
    } else if (action === "sub" && current_quantity >= 1) {
      draftTransfer.ticket_types[ticket_type_id] = current_quantity - 1;
    }

    if (draftTransfer.ticket_types[ticket_type_id] === 0) {
      delete draftTransfer.ticket_types[ticket_type_id];
    }

    let total = 0;
    const ticket_types = Object.keys(draftTransfer.ticket_types);
    ticket_types.forEach((key) => {
      total += draftTransfer.ticket_types[key];
    });

    draftTransfer.total = total;

    setDraftTransfer({ ...draftTransfer });
  };

  useEffect(() => {
    if (pathname) {
      const parts = pathname.split("/");
      const eId = parts.find((p) => p && p.startsWith("EV"));

      if (eId) {
        setEventId(eId);
      }
    }
  }, [pathname]);

  const [authenticatingUser, setAuthenticatingUser] =
    useState<CognitoUser | null>(null);

  const [signInErrors, updateSignInErrors, cleanError] = useErrors();
  const onSignIn = async (): Promise<CognitoUser | any> => {
    cleanError("phoneNumber");

    analytics.t("auth: started sign in");

    updateLoadingState("auth", true);
    const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`);
    const is_not_valid = validatePhoneNumberLength(
      parsedPhoneNumber?.number || ""
    );
    try {
      if (is_not_valid) {
        if (is_not_valid === "TOO_LONG") {
          updateSignInErrors({
            phoneNumber: {
              code: is_not_valid,
              message:
                "Phone number too long. Please check your number and try again.",
            },
          });
          console.log("toolong");
          throw Error(
            "Phone number too long. Please check your number and try again."
          );
        } else if (is_not_valid === "TOO_SHORT") {
          updateSignInErrors({
            phoneNumber: {
              code: is_not_valid,
              message:
                "Phone number too short. Please check your number and try again.",
            },
          });
          throw Error(
            "Phone number too short. Please check your number and try again."
          );
        } else {
          updateSignInErrors({
            phoneNumber: {
              code: "WRONG_FORMAT",
              message:
                "Invalid phone number format. Please check your number and try again.",
            },
          });
          throw Error(
            "Invalid phone number format. Please check your number and try again."
          );
        }
      } else if (parsedPhoneNumber && parsedPhoneNumber.number) {
        const cognitoUser = await Auth.signIn(parsedPhoneNumber!.number);
        setAuthenticatingUser(cognitoUser);
        updateLoadingState("auth", false);
        setModalType(ModalType.SIGN_IN_SMS_CODE);
      } else {
        updateSignInErrors({
          phoneNumber: {
            code: "WRONG_FORMAT",
            message:
              "Invalid phone number format. Please check your number and try again.",
          },
        });
        throw Error("Invalid phone number format");
      }
    } catch (err) {
      /*Cognito doesn't give us a lot of flexibility on error responses
      so we'll have to string match our 'User Not Found' error here
      and create a cognito user with the address as their username if they don't exist*/
      if (err instanceof Error) {
        // 👉️ err is type Error here
        console.log(err.message);
        const regex = /\[(.*?)\]/g;
        const groups = err.message.match(regex);
        if (groups && groups.length > 0) {
          const firstEntry = groups.pop();
          if (firstEntry === "[KYD:404]") {
            console.log("Create New User");
            const params = {
              username: parsedPhoneNumber!.number,
              password: getRandomString(20),
            };
            await Auth.signUp(params);
            PIXELS.completeregistration();
            const res = await onSignIn();
            updateLoadingState("auth", false);
            return res;
          } else if (firstEntry === "[KYD:Error]") {
            updateSignInErrors({
              phoneNumber: {
                code: "WRONG_FORMAT",
                message: err.message.split("[KYD:Error]").pop()?.trim(),
              },
            });
          }
        } else {
          updateLoadingState("auth", false);
          updateSignInErrors({
            phoneNumber: {
              code: "WRONG_FORMAT",
              message:
                err.message ||
                "Invalid phone number. Please check your number and try again.",
            },
          });
          throw err;
        }
      }
    }

    updateLoadingState("auth", false);
  };

  const onTransferPhoneValidate = async () => {
    updateLoadingState("validating_phone", true);
    try {
      analytics.t("transfer: validate phone", {
        step: "phone",
      });
      const { is_valid, code, message } =
        await actions.USER.validatePhoneForTransfer(eventId, phoneNumber);
      console.log("Is Valid: ", is_valid);
      if (is_valid) {
        setModalType(ModalType.TRANSFER_TICKETS_SELECT);
      } else if (code === 404) {
        setErrorMessage(message);
        setModalType(ModalType.TRANSFER_TICKETS_INVALID_PHONE);
      } else {
        analytics.t("transfer: validate error", {
          step: "phone",
          message,
        });
        dialog({ text: message });
      }
    } catch (err) {
      analytics.t("transfer: validate error", {
        step: "phone",
        //@ts-ignore
        message: err.message,
      });

      dialog({ text: err.message });
    }
    updateLoadingState("validating_phone", false);
  };

  const onTransferConfirm = async () => {
    updateLoadingState("transfer", true);
    try {
      analytics.t("transfer: did confirm", { step: "confirm" });
      const ticket_type_ids = Object.keys(draftTransfer.ticket_types);
      let ticket_ids: string[] = [];
      for (const ticket_type_id of ticket_type_ids) {
        const si = wallet?.summary_items.find(
          (si) => si.ticket_type_id === ticket_type_id
        );
        if (si) {
          const quantity = draftTransfer.ticket_types[ticket_type_id];
          if (quantity > 0) {
            const ticket_type_ticket_ids = si.ticket_ids.slice(0, quantity);
            ticket_ids = ticket_ids.concat(ticket_type_ticket_ids);
          }
        }
      }

      if (ticket_ids.length !== draftTransfer.total) {
        analytics.t("transfer: error", {
          step: "confirm",
          message:
            "An error occured. Your tickets have not been transfered. Please try again.",
        });
        //@ts-ignore
        throw Error(
          "An error occured. Your tickets have not been transfered. Please try again."
        );
      }

      await actions.USER.confirmTransfer(eventId, phoneNumber, ticket_ids);
      analytics.t("transfer: success", { step: "confirm" });
      const server_wallet = await actions.USER.fetchEventWallet(eventId);
      setWallet(server_wallet);
      setModalType(ModalType.TRANSFER_TICKETS_SUCCESS);
    } catch (err) {
      analytics.t("transfer: error", {
        step: "confirm",
        //@ts-ignore
        message: err.message,
      });
      //@ts-ignore
      dialog({ text: err.message });
    }
    updateLoadingState("transfer", false);
  };

  const onSMSCode = useCallback(
    async (code: string): Promise<CognitoUser | any> => {
      analytics.t("auth: entered sms code");

      cleanError("smsCode");
      updateLoadingState("auth", true);
      try {
        const res = await Auth.sendCustomChallengeAnswer(
          authenticatingUser,
          code
        );
        setSMSCode("");
        if (res.signInUserSession === null) {
          setAuthenticatingUser(res);
          updateSignInErrors({
            smsCode: {
              message: "Invalid code. Please try again.",
            },
          });
        } else {
          setAuthenticatingUser(null);
          setModalType(ModalType.INACTIVE);
        }
      } catch (err) {
        setSMSCode("");
        console.error(err);
        updateSignInErrors({
          smsCode: {
            message: err.message,
          },
        });
        console.log("Unhandled error", err.message);
        dialog({ text: err.message });
      }

      updateLoadingState("auth", false);
    },
    [authenticatingUser, setModalType, updateLoadingState, dialog]
  );

  useEffect(() => {
    if (smsCode && smsCode.length === 4) {
      onSMSCode(smsCode);
    }
  }, [smsCode, onSMSCode]);

  const renderModal = (modalType: ModalType) => (
    <Modal
      size={
        modalType >= ModalType.CHECKING_OUT
          ? "full"
          : modalType === ModalType.LIGHTBOX
          ? "xl"
          : "lg"
      }
      isOpen={true}
      closeOnOverlayClick={
        modalType !== ModalType.SECURING_TICKETS &&
        modalType !== ModalType.CLEARING_CART &&
        modalType < ModalType.CHECKING_OUT
      }
      onClose={() => setModalType(ModalType.INACTIVE)}
    >
      <ModalOverlay />
      <ModalContent margin={modalType >= ModalType.CHECKING_OUT ? 0 : 3}>
        {modalType !== ModalType.SECURING_TICKETS &&
          modalType !== ModalType.CLEARING_CART &&
          modalType !== ModalType.LIGHTBOX &&
          modalType < ModalType.CHECKING_OUT && <ModalCloseButton />}
        {modalType === ModalType.ADD_TO_CART_SUCCESS &&
          renderAddToCartSuccessBody()}
        {modalType === ModalType.ADD_TO_CART_SUCCESS &&
          renderAddToCartSuccessFooter()}

        {modalType === ModalType.SECURING_TICKETS &&
          renderSecuringTicketsBody()}

        {modalType === ModalType.SIGN_IN_PHONE_NUMBER &&
          renderSignInPhoneNumberBody()}
        {modalType === ModalType.SIGN_IN_PHONE_NUMBER &&
          renderSignInPhoneNumberFooter()}

        {modalType === ModalType.SIGN_IN_SMS_CODE && renderSignInSMSCodeBody()}
        {modalType === ModalType.SIGN_IN_SMS_CODE &&
          renderSignInSMSCodeFooter()}

        {modalType === ModalType.SMS_CODE_EXPLAINER &&
          renderSMSCodeExplainerBody()}
        {modalType === ModalType.SMS_CODE_EXPLAINER &&
          renderSMSCodeExplainerFooter()}

        {modalType === ModalType.EXPIRED_CART && renderExpiredCartBody()}
        {modalType === ModalType.EXPIRED_CART && renderExpiredCartFooter()}

        {modalType === ModalType.CHECKING_OUT && renderCheckingOutModal()}

        {modalType === ModalType.CHECK_OUT_SUCCESS &&
          renderCheckoutSuccessBody()}

        {modalType === ModalType.CHECK_OUT_FAILED && renderCheckoutFailedBody()}

        {modalType === ModalType.CHECK_OUT_TIMED_OUT &&
          renderCheckoutTimedOutBody()}

        {modalType === ModalType.CLEARING_CART && renderClearingCartBody()}

        {modalType === ModalType.TRANSFER_TICKETS_PHONE &&
          renderTransferTicketPhoneValidation()}
        {modalType === ModalType.TRANSFER_TICKETS_PHONE &&
          renderTransferPhoneNumberFooter()}

        {modalType === ModalType.TRANSFER_TICKETS_INVALID_PHONE &&
          renderInvalidPhoneBody(errorMessage)}
        {modalType === ModalType.TRANSFER_TICKETS_INVALID_PHONE &&
          renderDismessFooter("OK")}

        {modalType === ModalType.TRANSFER_TICKETS_TRANSFER_SUCCESS &&
          renderTransferSuccessBody()}
        {modalType === ModalType.TRANSFER_TICKETS_TRANSFER_SUCCESS &&
          renderDismessFooter("OK")}

        {modalType === ModalType.TRANSFER_TICKETS_SELECT &&
          renderTransferTicketsSelect("select")}
        {modalType === ModalType.TRANSFER_TICKETS_SELECT &&
          renderTicketSelectNextFooter()}

        {modalType === ModalType.TRANSFER_TICKETS_CONFIRM &&
          renderTransferTicketsSelect("confirm")}
        {modalType === ModalType.TRANSFER_TICKETS_CONFIRM &&
          renderTicketConfirmNextFooter()}

        {modalType === ModalType.CREATING_REMINDER &&
          renderCreatingReminderBody()}

        {modalType === ModalType.TRANSFER_TICKETS_SUCCESS &&
          renderTicketTransferSuccess()}
        {modalType === ModalType.TRANSFER_TICKETS_SUCCESS &&
          renderDismessFooter("OK")}

        {modalType === ModalType.SHOW_WALLET && renderTicketModal()}

        {modalType === ModalType.LIGHTBOX && renderLightbox()}
      </ModalContent>
    </Modal>
  );

  const renderAddToCartSuccessBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>✅ Success</Text>
        <Text fontSize={"sm"} color="gray.600">
          Your tickets have been reserved. You'll have 5 minutes to checkout
          before these tickets are released.
        </Text>
      </VStack>
    </ModalBody>
  );

  const renderLightbox = () => (
    <ModalBody bg={"transparent"}>
      {content && <Image src={content} />}
    </ModalBody>
  );

  const renderTransferSuccessBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>✅ Transfer Complete</Text>
        <Text fontSize={"sm"} color="gray.600">
          {`Your ticket(s) have been transfered`}
        </Text>
      </VStack>
    </ModalBody>
  );

  const renderCheckoutSuccessBody = () => {
    const [display_mode, event_name] = (content || "##").split("#");
    return (
      <ModalBody>
        <VStack spacing={5} my={5} pt={5}>
          <Image maxW="60px" src={logoFooterDark} />

          <Text fontWeight={"bold"} fontSize="xl">
            {display_mode === "rsvp"
              ? "✅ RSVP Success"
              : "✅ Checkout Success"}
          </Text>
          <Text
            display={"none"}
            textAlign={"center"}
            fontSize={"sm"}
            color="gray.600"
            maxW={"300px"}
          >
            {display_mode === "rsvp"
              ? "Your RSVP has been secured and is accessible through the QR code on your wallet"
              : "Your tickets have been secured and are accessible through the QR code on your wallet"}
          </Text>
          {wallet?.share_link ? (
            <VStack>
              <VStack rounded={"lg"} p={4} maxW={"400px"}>
                <Text textAlign={"center"} fontSize={"sm"} color={"gray.700"}>
                  {`👋 Invite your friends to grab their ${
                    display_mode === "rsvp" ? "rsvp" : "tickets"
                  } using your share link below`}
                </Text>
                <HStack>
                  <Text
                    fontSize={"sm"}
                    fontWeight={"bold"}
                    cursor={"pointer"}
                    borderBottomWidth={{ base: "0px", md: "1px" }}
                    borderBottomColor={"gray.300"}
                    onClick={async () => {
                      analytics.t("event: copied share link", {
                        location: "successmodal",
                      });
                      if (
                        window &&
                        window.navigator &&
                        window.navigator.share
                      ) {
                        try {
                          await window.navigator.share({
                            title: event_name,
                            url: wallet?.share_link,
                          });
                          console.log("Data was shared successfully");
                        } catch (err) {
                          //@ts-ignore
                          console.error("Share failed:", err.message);
                        }
                      } else {
                        showSuccessToast(toast, "✅ Link copied!");
                        onCopy();
                      }
                    }}
                  >
                    {wallet?.share_link.split("://").pop()}
                  </Text>
                  <Text
                    borderWidth={{ base: "1px", md: "0px" }}
                    borderColor="black"
                    bg="none"
                    fontSize={"sm"}
                    px={2}
                    rounded={"md"}
                    minW={{ base: "60px", md: "100px" }}
                    color={{ base: "black", md: "blue.500" }}
                    fontWeight={"bold"}
                    cursor={"pointer"}
                    onClick={async () => {
                      analytics.t("event: copied share link", {
                        location: "successmodal",
                      });
                      if (
                        window &&
                        window.navigator &&
                        window.navigator.share
                      ) {
                        try {
                          await window.navigator.share({
                            title: event_name,
                            url: wallet?.share_link,
                          });
                        } catch (err) {
                          //@ts-ignore
                          console.error("Share failed:", err.message);
                        }
                      } else {
                        showSuccessToast(toast, "✅ Link copied!");
                        onCopy();
                      }
                    }}
                  >
                    {shareCTA}
                  </Text>
                </HStack>
              </VStack>
              <Text fontWeight={"bold"} fontSize={"sm"}>
                or
              </Text>
            </VStack>
          ) : null}
          <Button
            variant="kydDark"
            borderWidth={"1px"}
            borderColor={"black"}
            onClick={() => {
              setModalType(ModalType.INACTIVE);
              const searchParams = new URLSearchParams(window.location.search);
              navigate(`/e/${eventId}/wallet?${searchParams.toString()}`);
            }}
          >
            {`View ${display_mode === "rsvp" ? "RSVP" : "Tickets"}`}
          </Button>
        </VStack>
      </ModalBody>
    );
  };

  const renderCheckoutFailedBody = () => (
    <ModalBody>
      <VStack spacing={5} my={5} pt={5}>
        <Text fontWeight={"bold"} fontSize="xl">
          ❌ Checkout Failed
        </Text>
        <Text fontSize={"sm"} color="gray.600" maxW={"300px"}>
          Your card was either declined, or verification failed with your bank.
          Please try again with a different payment method.
        </Text>
        <Button
          variant="kydDark"
          onClick={() => {
            setModalType(ModalType.INACTIVE);
          }}
        >
          Go Back
        </Button>
      </VStack>
    </ModalBody>
  );

  const renderCheckoutTimedOutBody = () => (
    <ModalBody>
      <VStack spacing={5} my={5} pt={5}>
        <Text fontWeight={"bold"} fontSize="xl">
          ⚠️ Checkout Incomplete
        </Text>
        <Text fontSize={"sm"} color="gray.600" maxW={"300px"}>
          We were unable to determine whether or not your checkout succeeded. If
          your tickets do not show up within 1 hour, please contact support
          below via the chat bubble.
        </Text>
        <Button
          variant="kydDark"
          onClick={() => {
            setModalType(ModalType.INACTIVE);
            const searchParams = new URLSearchParams(window.location.search);
            navigate(`/e/${eventId}/wallet?${searchParams.toString()}`);
          }}
        >
          View Wallet
        </Button>
      </VStack>
    </ModalBody>
  );

  const renderSMSCodeExplainerBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontSize={"lg"} fontWeight="bold">
          About our SMS Code
        </Text>
        <Text>
          We've sent your kyd labs SMS code to +{phoneNumber}. If you still
          haven't received it, try again below. SMS verification is a secure way
          to login without a password.
        </Text>
      </VStack>
    </ModalBody>
  );

  const renderCheckingOutModal = () => (
    <ModalBody>
      <VStack spacing={5} my={5} pt={5}>
        <Image maxW="60px" src={logoFooterDark} />

        <Text fontWeight={"bold"}>
          {content === "rsvp" ? "😎 Finalizing RSVP" : "🛒 Checking Out"}
        </Text>
        <Text fontSize={"sm"} color="gray.600" maxW={"300px"}>
          {content === "rsvp"
            ? "We are processing your RSVP! This may take up to 30 seconds."
            : "Give us a moment while we secure your tickets to the event! This may take up to 30 seconds."}
        </Text>
        <Spinner color="black" size={"lg"} />
      </VStack>
    </ModalBody>
  );

  const renderSMSCodeExplainerFooter = () => (
    <ModalFooter>
      <HStack spacing={2}>
        <Button
          isLoading={loading.auth}
          onClick={() => {
            setSMSCode("");
            onSignIn();
          }}
          color="white"
          bg={"black"}
          _hover={{ bg: "gray.800" }}
        >
          Try Again
        </Button>
        <Button
          onClick={() => {
            analytics.t("auth: retried sms code");
            setModalType(ModalType.SIGN_IN_SMS_CODE);
          }}
        >
          Cancel
        </Button>
      </HStack>
    </ModalFooter>
  );

  const renderSecuringTicketsBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>
          🎟️ Securing your {content === "rsvp" ? "RSVP" : "tickets"}!
        </Text>
        <Text fontSize={"sm"} color="gray.600">
          Give us a moment while we secure your{" "}
          {content === "rsvp" ? "RSVP" : "tickets"} for the event
        </Text>
        <Spinner color="black" size={"lg"} />
      </VStack>
    </ModalBody>
  );

  const renderClearingCartBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>🧹 Clearing cart</Text>
        <Text fontSize={"sm"} color="gray.600">
          Please wait. We are clearing your cart.
        </Text>
        <Spinner color="teal.500" size={"lg"} />
      </VStack>
    </ModalBody>
  );

  const renderInvalidPhoneBody = (errorMessage?: string) => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>❌ Phone Number Invalid</Text>
        <Text fontSize={"sm"}>{errorMessage}</Text>
      </VStack>
    </ModalBody>
  );

  const renderCreatingReminderBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Spinner color="black" size="lg" />
        <Text fontSize={"sm"}>Creating reminder...</Text>
      </VStack>
    </ModalBody>
  );

  const renderTransferTicketPhoneValidation = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"black"}>Transfer to Phone Number</Text>
        <VStack w="100%" maxW={"300px"}>
          <PhoneInput
            disabled={loading.validating_phone}
            autoFormat={true}
            inputProps={{
              placeholder: "Mobile number",
            }}
            inputStyle={{
              width: "100%",
              height: "60px",
              backgroundColor: "rgba(250,250,250,1.0)",
              fontFamily: "Work sans",
              borderWidth: "1px",
              borderRadius: "5px",
            }}
            countryCodeEditable={false}
            country={"us"}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <Text fontSize={"xs"} color="gray.600">
            Please enter the phone number of the person you'd like to transfer
            tickets to.
          </Text>
        </VStack>
      </VStack>
    </ModalBody>
  );

  const renderAddToCartSuccessFooter = () => (
    <ModalFooter>
      <HStack spacing={2}>
        <Button onClick={() => setModalType(ModalType.INACTIVE)}>
          Continue Shopping
        </Button>
        <Button
          variant="kydDark"
          onClick={() => {
            setModalType(ModalType.INACTIVE);
            const searchParams = new URLSearchParams(window.location.search);
            navigate(`/e/${eventId}?${searchParams.toString()}`);
          }}
        >
          Checkout
        </Button>
      </HStack>
    </ModalFooter>
  );

  const renderSignInPhoneNumberFooter = () => (
    <ModalFooter justifyContent={"space-between"}>
      <Button
        w="100%"
        isLoading={loading.auth}
        variant="kydDark"
        isDisabled={!phoneNumber || phoneNumber.length <= 1}
        onClick={onSignIn}
      >
        Next
      </Button>
    </ModalFooter>
  );

  const renderTicketSelectNextFooter = () => (
    <ModalFooter justifyContent={"space-between"}>
      <Stack w="100%">
        <Button
          w="100%"
          variant="kydDark"
          isDisabled={!draftTransfer || draftTransfer.total === 0}
          onClick={() => {
            analytics.t("transfer: next", { step: "selecttickets" });
            setModalType(ModalType.TRANSFER_TICKETS_CONFIRM);
          }}
        >
          Next
        </Button>
        <Button
          w="100%"
          onClick={() => {
            analytics.t("transfer: go back", { step: "selecttickets" });
            setModalType(ModalType.TRANSFER_TICKETS_PHONE);
          }}
        >
          Go Back
        </Button>
      </Stack>
    </ModalFooter>
  );

  const renderTicketConfirmNextFooter = () => (
    <ModalFooter justifyContent={"space-between"}>
      <Stack w="100%">
        <Button
          w="100%"
          color="white"
          bg={"green.500"}
          _hover={{ bg: "gray.800" }}
          isDisabled={loading.transfer}
          isLoading={loading.transfer}
          onClick={onTransferConfirm}
        >
          Confirm and Transfer
        </Button>
        <Button
          w="100%"
          isDisabled={loading.transfer}
          onClick={() => {
            analytics.t("transfer: go back", { step: "confirm" });
            setModalType(ModalType.TRANSFER_TICKETS_SELECT);
          }}
        >
          Go Back
        </Button>
      </Stack>
    </ModalFooter>
  );

  const renderTransferPhoneNumberFooter = () => (
    <ModalFooter justifyContent={"space-between"}>
      <Button
        w="100%"
        isLoading={loading.validating_phone}
        variant={"kydDark"}
        isDisabled={!phoneNumber || phoneNumber.length <= 1}
        onClick={onTransferPhoneValidate}
      >
        Next
      </Button>
    </ModalFooter>
  );

  /*const renderInviteUserFooter = () => (
    <ModalFooter justifyContent={"space-between"}>
      <Button
        w="100%"
        isLoading={loading.invite}
        color="white"
        bg={"black"}
        _hover={{ bg: "gray.800" }}
        isDisabled={!phoneNumber || phoneNumber.length <= 1}
        onClick={onSendInvite}
      >
        Send Invite
      </Button>
    </ModalFooter>
  );*/

  const renderDismessFooter = (title: string) => (
    <ModalFooter justifyContent={"space-between"}>
      <Button
        w="100%"
        variant="kydDark"
        onClick={() => setModalType(ModalType.INACTIVE)}
      >
        {title}
      </Button>
    </ModalFooter>
  );
  const renderSignInPhoneNumberBody = () => {
    const hasError = !!signInErrors["phoneNumber"];
    console.log({ errors: signInErrors });
    console.log({ hasError });
    return (
      <ModalBody>
        <VStack spacing={3} my={5} pt={5}>
          <Text fontWeight={"black"}>
            {content ? content : "Register / Sign In"}
          </Text>
          <VStack w="100%" maxW={"300px"}>
            <PhoneInput
              disabled={loading.auth}
              autoFormat={true}
              inputProps={{
                placeholder: "Mobile number",
              }}
              inputStyle={{
                width: "100%",
                height: "60px",
                backgroundColor: "rgba(250,250,250,1.0)",
                fontFamily: "Work sans",
                borderWidth: "1px",
                borderRadius: "5px",
              }}
              isValid={!hasError}
              countryCodeEditable={false}
              country={"us"}
              value={phoneNumber}
              onChange={(phone, data, e, fmtVal) => {
                console.log(data, e, fmtVal);
                cleanError("phoneNumber");
                setPhoneNumber(phone);
              }}
            />
            {hasError ? (
              <Text color="gray.600" fontSize="xs">
                ⚠️ {signInErrors["phoneNumber"].message}
              </Text>
            ) : (
              <Text fontSize={"xs"} color="gray.600">
                By registering you accept our{" "}
                <a
                  href="https://www.iubenda.com/terms-and-conditions/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Terms Of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.iubenda.com/privacy-policy/22517592"
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  Privacy Policy
                </a>
                .
              </Text>
            )}
          </VStack>
        </VStack>
      </ModalBody>
    );
  };

  const renderSignInSMSCodeBody = () => {
    const hasError = signInErrors["smsCode"];
    return (
      <ModalBody>
        <VStack spacing={4} my={5} pt={5}>
          <Text fontWeight={"black"}>Register / Sign In</Text>
          <Text fontSize={"sm"} color="gray.700">
            A four digit code has been sent to +{phoneNumber}. Please enter it
            below to validate your device.
          </Text>
          <VStack w="100%" maxW={"300px"}>
            <OtpInput
              containerStyle={{ width: "100%" }}
              inputStyle={{
                marginLeft: "5px",
                marginRight: "5px",
                width: "60px",
                borderWidth: "1px",
                borderRadius: "5px",
                height: "60px",
                backgroundColor: "rgba(250,250,250,1.0)",
                fontSize: "largest",
                borderColor: hasError ? "red" : "initial",
              }}
              inputType="tel"
              value={smsCode}
              onChange={(code) => {
                cleanError("smsCode");
                setSMSCode(code);
              }}
              numInputs={4}
              renderSeparator={
                <span style={{ color: "rgb(200,200,200)" }}>-</span>
              }
              renderInput={(props) => (
                <Input
                  {...props}
                  isDisabled={loading.auth}
                  isInvalid={hasError}
                />
              )}
            />
          </VStack>
          {hasError ? (
            <Text color="gray.600" fontSize="xs">
              ⚠️ {signInErrors["smsCode"].message}
            </Text>
          ) : null}

          <Text
            fontSize={"sm"}
            fontWeight="bold"
            cursor={"pointer"}
            onClick={() => setModalType(ModalType.SMS_CODE_EXPLAINER)}
          >
            Didn't receive SMS code or need help?
          </Text>
        </VStack>
      </ModalBody>
    );
  };

  const renderExpiredCartBody = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>Tickets Expired!</Text>
        <Text fontSize={"sm"} color="gray.700">
          Your tickets have been released. Please continue shopping to reserve
          tickets again.
        </Text>
      </VStack>
    </ModalBody>
  );

  const renderTicketTransferSuccess = () => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <Text fontWeight={"bold"}>✅ Tickets Transferred</Text>
        <Text fontSize={"sm"} color="gray.700">
          Your tickets have been transferred to <strong>+{phoneNumber}</strong>
        </Text>
      </VStack>
    </ModalBody>
  );

  const renderTransferTicketsSelect = (mode: "select" | "confirm") => (
    <ModalBody>
      <VStack spacing={3} my={5} pt={5}>
        <VStack spacing={0}>
          <Text fontWeight={"bold"}>Select Tickets To Transfer</Text>
          <Text fontSize={"sm"}>
            These tickets will be transferred to <strong>+{phoneNumber}</strong>
          </Text>
        </VStack>

        {wallet &&
          wallet.summary_items
            .filter((si) =>
              mode === "confirm"
                ? draftTransfer.ticket_types[si.ticket_type_id] > 0
                : true
            )
            .map((s) => (
              <HStack key={s.ticket_type_id} minW={"50%"} w="100%">
                <Stack spacing={0} w="100%">
                  <Text fontWeight={"medium"}>{s.name}</Text>
                  <Text fontSize={"sm"} color="gray.600">
                    {s.quantity} available
                  </Text>
                </Stack>

                <HStack w="100%" minW="25%" justifyContent={"flex-end"}>
                  <IconButton
                    display={mode === "select" ? "inherit" : "none"}
                    onClick={() =>
                      onUpdateTicketTypeQuantity(s.ticket_type_id, "sub")
                    }
                    aria-label="Subtract"
                    icon={<MinusIcon />}
                  />
                  <Text fontWeight={"bold"} textAlign={"center"} minW={"20px"}>
                    {draftTransfer
                      ? draftTransfer.ticket_types[s.ticket_type_id] || 0
                      : 0}
                  </Text>
                  <IconButton
                    display={mode === "select" ? "inherit" : "none"}
                    onClick={() =>
                      onUpdateTicketTypeQuantity(s.ticket_type_id, "add")
                    }
                    aria-label="Add"
                    icon={<AddIcon />}
                  />
                </HStack>
              </HStack>
            ))}
        {mode === "confirm" && (
          <Text fontSize={"sm"} color="gray.700">
            You are transferring{" "}
            <strong>{draftTransfer.total} ticket(s)</strong>. Please confirm
            below to finalize the transfer. This transfer cannot be undone.
          </Text>
        )}
      </VStack>
    </ModalBody>
  );

  const renderExpiredCartFooter = () => (
    <ModalFooter>
      <HStack spacing={2}>
        <Button
          variant="kydDark"
          onClick={() => {
            analytics.t("cart: continue shopping");
            setModalType(ModalType.INACTIVE);
            const searchParams = new URLSearchParams(window.location.search);
            navigate(`/e/${eventId}?${searchParams.toString()}`);
          }}
        >
          Continue Shopping
        </Button>
      </HStack>
    </ModalFooter>
  );

  const renderSignInSMSCodeFooter = () => (
    <ModalFooter justifyContent={"space-between"}>
      <Button
        w="100%"
        isLoading={loading.auth}
        variant="kydDark"
        isDisabled={!smsCode || smsCode.length < 4 || loading.auth}
        onClick={() => onSMSCode(smsCode)}
      >
        Next
      </Button>
    </ModalFooter>
  );

  const renderTicketModal = () => (
    <Modal
      size={["full", null, "4xl"]}
      isOpen={true}
      onClose={() => setModalType(ModalType.INACTIVE)}
    >
      <ModalOverlay />
      <ModalContent zIndex={"1001"}>
        <ModalBody>
          <Stack
            spacing={[6, null, 4]}
            flexDir={["column", null, "row"]}
            px={5}
            py={[4, null, 7]}
          >
            <Stack
              justifyContent={"center"}
              spacing={[2, null, 5]}
              w={["100%", null, "50%"]}
            >
              <Text
                lineHeight={"shorter"}
                fontSize={["lg", null, "5xl"]}
                fontWeight="bold"
              >
                Scan this QR code
              </Text>
              <Text fontSize={["xs", null, "sm"]}>
                This QR code contains all of your tickets. Screenshots not
                valid.
              </Text>
            </Stack>
            <VStack
              bg="white"
              rounded={"lg"}
              spacing={5}
              w={["100%", null, "50%"]}
              py={5}
            >
              <Box
                animation={"3s rotate linear infinite"}
                borderWidth="5px"
                style={{
                  borderImage:
                    "conic-gradient(from var(--angle), #F9FD50, #ED64A6, #F9FD50) 2",
                }}
                p={5}
              >
                {content && (
                  <QRCodeSVG
                    value={content}
                    size={qrCodeSize}
                    mode="L"
                    imageSettings={{
                      src: "https://content.kydlabs.com/system/crown.png",
                      x: undefined,
                      y: undefined,
                      height: 40,
                      width: 40,
                      excavate: true,
                    }}
                  />
                )}
              </Box>
              <Text
                onClick={() => setModalType(ModalType.INACTIVE)}
                textDecor={"underline"}
                color="gray.500"
                fontWeight={"bold"}
                cursor="pointer"
              >
                Back To My Wallet
              </Text>
            </VStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  return renderModal(modalType);
}

export default KYDModal;
