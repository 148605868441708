import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { KYDEvent, KYDEventSummary } from "@common/interfaces/KYDEvent";
import { KYDFeed, KYDFeedItem } from "@common/interfaces/KYDOrganization";

const buildEventLink = (link: string) => {
  if (link && window) {
    const search = window.location.search;
    return search && search.length > 1
      ? `${link}${window.location.search}`
      : link;
  }
};

const Event = ({
  kyd_event,
  feed,
  displayAllMode,
  index,
}: {
  kyd_event: KYDEventSummary;
  feed: KYDFeed;
  displayAllMode: Record<string, boolean>;
  index: number;
}) => {
  return (
    <a
      key={kyd_event.id}
      style={{ width: "100%" }}
      href={buildEventLink(kyd_event.link)}
      target="_blank"
      rel={"noreferrer"}
    >
      <Box
        p={3}
        w="100%"
        h="140px"
        borderBottom={
          index === (feed?.kyd_events.length || 0) - 1 &&
          !displayAllMode.kyd_events
            ? "0px"
            : "1px"
        }
        borderColor={"gray.300"}
      >
        <HStack h="100%" w="100%" justify={"space-between"}>
          <HStack h="100%">
            <Image
              rounded={"md"}
              src={kyd_event.image}
              maxH={"100px"}
              maxW={"100px"}
            />
            <Stack spacing={0}>
              <Text fontWeight="semibold">{kyd_event.name}</Text>
              <Text color="#3300FF" fontSize={"sm"} fontWeight={"medium"}>
                {kyd_event.display_start_at}
              </Text>
              <HStack w="100%">
                {kyd_event.venue.name ? (
                  <Text fontSize={"sm"}>{kyd_event.venue.name}</Text>
                ) : null}
                {kyd_event.venue.city ? (
                  <Text fontSize={"sm"} fontWeight={"medium"}>
                    {kyd_event.venue.city}
                  </Text>
                ) : null}
              </HStack>
            </Stack>
          </HStack>
          <Stack
            display={{ base: "none", md: "flex" }}
            justify={"center"}
            h="100%"
            spacing={0}
            minW={"124px"}
          >
            <Box
              borderWidth={"1px"}
              borderColor={"black"}
              rounded={"md"}
              px={4}
              py={2}
              textAlign="center"
              fontWeight={"medium"}
              _hover={{ bg: "gray.100" }}
              color="black"
            >
              {kyd_event.display_mode === "rsvp" ? "RSVP" : "Get Tickets"}
            </Box>
          </Stack>
        </HStack>
      </Box>
    </a>
  );
};

export default Event;
