import React, { useCallback, useEffect, useState } from "react";
import actions from "../actions";
import {
  Text,
  Stack,
  HStack,
  Spinner,
  VStack,
  Divider,
  Button,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent } from "@common/interfaces/KYDEvent";
import { QrScanner } from "@yudiel/react-qr-scanner";

import { KYDEvent } from "@common/interfaces/KYDEvent";
import { ScanResponse } from "@common/interfaces/KYDTicket";
import { ScannerState, analytics, ModalType } from "../utils";
import { useParams, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import { Cache as KYDCache } from "aws-amplify";
import Div100vh from "react-div-100vh";
import { useDialog } from "../Common/Dialog";

type OnlyBoolsAndHorses = {
  [key: string]: boolean;
};

enum ScannerModalType {
  INACTIVE,
  ENTER_PHONE,
  VALIDATING_PHONE,
}

function KYDScanner() {
  const [walletToken, setWalletToken] = useState<string | null>(null);
  const [scannerState, setScannerState] = useState(ScannerState.INACTIVE);
  const [scanResponse, setScanResponse] = useState<ScanResponse | null>(null);
  const [kydEvent, setKydEvent] = useState<IKYDEvent | undefined>(undefined);
  const [_openTicketTypeDescriptions, setOpenTicketTypeDescriptions] =
    useState<OnlyBoolsAndHorses>({});
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accessCode, setAccessCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [modalType, setModalType] = useState(ScannerModalType.INACTIVE);

  const navigate = useNavigate();
  const dialog = useDialog();

  const { event_id } = useParams();
  useEffect(() => {
    const fetchKYDEvent = async (_event_id: string) => {
      try {
        const _ = await actions.USER.fetchEvent(_event_id);
        analytics.t("scanner: fetched event");

        if (_) {
          document.title = _.name;
        }
        setKydEvent(_);
      } catch (err) {
        dialog({ text: err.message });
      }
    };

    if (event_id) {
      fetchKYDEvent(event_id);
    }
  }, [event_id, dialog]);

  const validateWalletToken = useCallback(
    async (e_id: string, accessToken: string, token: string, type?: string) => {
      setScannerState(ScannerState.PROCESSING);
      try {
        if (type === "phone") {
          const parsedPhoneNumber = parsePhoneNumber(`+${token}`);
          if (parsedPhoneNumber && parsedPhoneNumber.number) {
            token = parsedPhoneNumber.number;
          } else {
            throw Error("Invalid phone number format");
          }
        }

        const result = await actions.SCANNER.validateWalletToken(
          e_id,
          accessToken,
          token,
          type
        );
        setPhoneNumber("");

        if (result) {
          console.log(result);
          setScanResponse(result);
          if (!result.is_valid) {
            setScannerState(ScannerState.INVALID_TICKETS);
          } else {
            const openStates: OnlyBoolsAndHorses = {};
            result.ticket_types.forEach((tt) => {
              openStates[tt.id] = false;
            });

            setOpenTicketTypeDescriptions({ ...openStates });
            setScannerState(ScannerState.VALIDATED);
          }
        } else {
          setScannerState(ScannerState.INVALID_TICKETS);
        }
      } catch (err) {
        //@ts-ignore
        setScanResponse({
          //@ts-ignore
          error_message: err.message || "Error. Please scan again.",
          is_valid: false,
        });
        setScannerState(ScannerState.INVALID_TICKETS);
        console.error(err);
      }
    },
    []
  );

  const validateAccessCode = useCallback(
    async (e_id: string, code: string) => {
      setScannerState(ScannerState.VALIDATING_ACCESS_CODE);
      if (!accessCode && code) {
        setAccessCode(code);
      }

      try {
        const result = await actions.SCANNER.validateAccessCode(e_id, code);
        console.log(result);

        if (!result.access_token) {
          throw Error("No access token returned. Please contact support.");
        }

        KYDCache.setItem(`${e_id}.scannercode`, code);
        setAccessToken(result.access_token);
        setScannerState(ScannerState.READY);
      } catch (err) {
        KYDCache.removeItem(`${e_id}.scannercode`);
        dialog({ text: err.message });
        setAccessCode("");
        setScannerState(ScannerState.ACCESS_CODE_REQUIRED);
        console.error(err);
      }
    },
    [accessCode]
  );

  useEffect(() => {
    if (kydEvent) {
      const cached_scanner_code = KYDCache.getItem(
        `${kydEvent.id}.scannercode`
      );
      if (cached_scanner_code) {
        setAccessCode(accessCode);
        validateAccessCode(kydEvent.id, cached_scanner_code);
      } else {
        setScannerState(ScannerState.ACCESS_CODE_REQUIRED);
      }
    }
  }, [kydEvent, validateAccessCode]);

  const handleCheckIn = useCallback(
    async (e_id: string, accessToken: string, checkInToken: string) => {
      setScannerState(ScannerState.CHECKING_IN);
      try {
        const result = await actions.SCANNER.createCheckIn(
          e_id,
          accessToken,
          checkInToken
        );
        setScannerState(ScannerState.CHECK_IN_SUCCESS);
        console.log(result);
      } catch (err) {
        setScanResponse(null);
        setScannerState(ScannerState.CHECK_IN_FAILED);
        console.error(err);
      }
    },
    []
  );

  useEffect(() => {
    console.log("Data Changed:", walletToken);
    if (
      event_id &&
      walletToken &&
      accessToken &&
      scannerState === ScannerState.READY
    ) {
      validateWalletToken(event_id, accessToken, walletToken);
    }
  }, [walletToken, event_id, scannerState, accessToken, validateWalletToken]);

  const renderReadyContent = () => (
    <VStack>
      <Text fontSize={"lg"} fontWeight="bold" color="white">
        Scan the customer's QR code
      </Text>
      <Divider />
      <Text fontSize={"lg"} fontWeight="bold" color="white">
        OR
      </Text>
      <Button
        w="100%"
        bgGradient="linear(to-r, #667eea, #764ba2)"
        _hover={{ bg: "#764ba2" }}
        color="white"
        h="50px"
        onClick={() => setModalType(ScannerModalType.ENTER_PHONE)}
      >
        Enter Phone Number
      </Button>
    </VStack>
  );

  const renderInvalidTicketOverlay = () => (
    <VStack
      pos={"absolute"}
      bg="red.500"
      h="100%"
      w="100%"
      justify="center"
      zIndex={1000}
      p={2}
    >
      {scannerState === ScannerState.INVALID_TICKETS && (
        <Text fontSize={"lg"} fontWeight="bold" color="white">
          {scanResponse && scanResponse.error_message}
          {!scanResponse && "Error Occured. Please scan again."}
        </Text>
      )}
    </VStack>
  );

  const resetScanner = () => {
    setScanResponse(null);
    setWalletToken(null);
    setScannerState(ScannerState.READY);
  };

  const renderInvalidTicketContent = () => (
    <VStack w="100%">
      <Button
        onClick={resetScanner}
        bgGradient="linear(to-r, #F56565, #ED64A6)"
        w="100%"
        color="white"
        _hover={{ bg: "#F56565" }}
      >
        Scan Another QR Code
      </Button>
    </VStack>
  );

  const renderValidTicketOverlay = () => (
    <VStack
      pos={"absolute"}
      bg="green.500"
      h="100%"
      w="100%"
      justify="center"
      zIndex={1000}
    >
      {scanResponse?.env === "test" ? (
        <Text fontSize={"lg"} fontWeight="bold" color="white">
          {`[TEST] Valid tickets found`}
        </Text>
      ) : (
        <Text fontSize={"lg"} fontWeight="bold" color="white">
          Valid tickets found
        </Text>
      )}
      <Text fontSize={"lg"} fontWeight="bold" color="white">
        View options below
      </Text>
    </VStack>
  );

  const renderCheckInSuccessOverlay = () => (
    <VStack
      pos={"absolute"}
      bg="green.500"
      h="100%"
      w="100%"
      justify="center"
      zIndex={1000}
    >
      <Text fontSize={"lg"} fontWeight="bold" color="white">
        Check-In complete
      </Text>
    </VStack>
  );

  const renderProcessingTicketOverlay = () => (
    <VStack
      pos={"absolute"}
      bg="rgba(0,0,0,.80)"
      h="100%"
      w="100%"
      justify="center"
      zIndex={1000}
    >
      <Spinner size={"xl"} color="white" />
      <Text color="white" fontWeight={"bold"}>
        Loading tickets...
      </Text>
    </VStack>
  );

  const renderValidTicketContent = () => (
    <VStack spacing={4} w="100%">
      <VStack mt={0} spacing={4} w="100%">
        {scanResponse &&
          accessToken &&
          scanResponse.check_in_token &&
          scannerState !== ScannerState.CHECK_IN_SUCCESS &&
          scannerState !== ScannerState.INVALID_TICKETS && (
            <Button
              isLoading={scannerState === ScannerState.CHECKING_IN}
              onClick={() =>
                handleCheckIn(
                  event_id!,
                  accessToken,
                  scanResponse.check_in_token!
                )
              }
              w="100%"
              color="white"
              bgGradient={"linear(to-r, #0ba360, #3cba92)"}
              _hover={{ bg: "#0ba360" }}
              h="50px"
            >
              {`Check In All ${
                scanResponse.env === "test" ? "[TEST] " : ""
              }Tickets (${scanResponse?.total_tickets})`}
            </Button>
          )}
        <Button
          isDisabled={scannerState === ScannerState.CHECKING_IN}
          onClick={resetScanner}
          w="100%"
          bgGradient="linear(to-r, #F56565, #ED64A6)"
          _hover={{ bg: "#F56565" }}
          color="white"
          h="50px"
        >
          Scan Another QR Code
        </Button>
      </VStack>
      <Stack w="100%" color="white">
        {scanResponse && scanResponse.valid_tickets.length > 0 && (
          <>
            <Text textAlign={"left"} fontSize="xl" fontWeight={"bold"}>
              {`Valid Tickets (${scanResponse?.valid_tickets.length || "0"})`}
            </Text>
            {scanResponse.valid_tickets.map((tt) => (
              <HStack
                key={tt.id}
                borderWidth={"1px"}
                justify="space-between"
                rounded="lg"
                borderColor={"white"}
                w="100%"
                p={3}
                onClick={() => {
                  setOpenTicketTypeDescriptions((prev: any) => {
                    prev[tt.id] = !prev[tt.id];
                    return { ...prev };
                  });
                }}
              >
                <Stack spacing={0}>
                  <Text textAlign={"left"} fontWeight={"bold"}>
                    {tt.name}
                  </Text>
                  {tt.error_message ? (
                    <Text
                      textAlign={"left"}
                      color={"red.500"}
                      fontWeight={"bold"}
                    >
                      {tt.error_message}
                    </Text>
                  ) : null}
                  {/*tt.description && (
                    <Accordion
                      onChange={() => {
                        setOpenTicketTypeDescriptions((prev: any) => {
                          prev[tt.id] = !prev[tt.id];
                          return { ...prev };
                        });
                      }}
                      index={openTicketTypeDescriptions[tt.id] ? 0 : undefined}
                      allowToggle={true}
                    >
                      <AccordionItem p={0} border={"none"}>
                        <AccordionButton p={0}>
                          <Text fontSize={"xs"} textAlign="left">
                            Detail
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel textAlign={"left"}>
                          <Text fontSize={"sm"}>{tt.description}</Text>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    )*/}
                </Stack>
                {/*<Stack minW={"60px"} spacing={0}>
                  <Text fontSize={"xx-small"}># Of Tickets</Text>
                  <Text>{tt.quantity}</Text>
                    </Stack>*/}
              </HStack>
            ))}
          </>
        )}
        {scanResponse && scanResponse.valid_tickets.length === 0 && (
          <>
            <Text textAlign={"left"} fontSize="xl" fontWeight={"bold"}>
              {`Valid Tickets (${scanResponse?.valid_tickets.length || "0"})`}
            </Text>
            <Text>No valid tickets</Text>
          </>
        )}
      </Stack>
      <Stack w="100%" color="white">
        {scanResponse && scanResponse.invalid_tickets && (
          <>
            <Text textAlign={"left"} fontSize="xl" fontWeight={"bold"}>
              {`Invalid Tickets (${
                scanResponse?.invalid_tickets.length || "0"
              })`}
            </Text>
            {scanResponse.invalid_tickets.map((tt) => (
              <HStack
                key={tt.id}
                borderWidth={"1px"}
                justify="space-between"
                rounded="lg"
                borderColor={"white"}
                w="100%"
                p={3}
                onClick={() => {
                  setOpenTicketTypeDescriptions((prev: any) => {
                    prev[tt.id] = !prev[tt.id];
                    return { ...prev };
                  });
                }}
              >
                <Stack spacing={0}>
                  <Text textAlign={"left"} fontWeight={"bold"}>
                    {tt.name}
                  </Text>
                  {tt.error_message ? (
                    <Text
                      textAlign={"left"}
                      color={"red.500"}
                      fontWeight={"bold"}
                    >
                      {tt.error_message}
                    </Text>
                  ) : null}
                  {/*tt.description && (
                    <Accordion
                      onChange={() => {
                        setOpenTicketTypeDescriptions((prev: any) => {
                          prev[tt.id] = !prev[tt.id];
                          return { ...prev };
                        });
                      }}
                      index={openTicketTypeDescriptions[tt.id] ? 0 : undefined}
                      allowToggle={true}
                    >
                      <AccordionItem p={0} border={"none"}>
                        <AccordionButton p={0}>
                          <Text fontSize={"xs"} textAlign="left">
                            Detail
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel textAlign={"left"}>
                          <Text fontSize={"sm"}>{tt.description}</Text>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    )*/}
                </Stack>
                {/*<Stack minW={"60px"} spacing={0}>
                  <Text fontSize={"xx-small"}># Of Tickets</Text>
                  <Text>{tt.quantity}</Text>
                    </Stack>*/}
              </HStack>
            ))}
          </>
        )}
      </Stack>
      <Stack w="100%" color="white">
        {scanResponse && scanResponse.user && (
          <>
            <Text textAlign={"left"} fontSize="xl" fontWeight={"bold"}>
              Ticket Holder
            </Text>

            <Stack
              borderWidth={"1px"}
              justify="space-between"
              rounded="lg"
              borderColor={"white"}
              w="100%"
              p={3}
              textAlign="left"
              spacing={0}
            >
              <HStack>
                <Text>{scanResponse.user.first_name}</Text>
                <Text>{scanResponse.user.last_name}</Text>
              </HStack>
              <Text>{scanResponse.user.email}</Text>
            </Stack>
          </>
        )}
      </Stack>
    </VStack>
  );

  const renderAccessCodeModal = () => (
    <Modal
      size={"lg"}
      isOpen={true}
      closeOnOverlayClick={false}
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={5}>
          {scannerState === ScannerState.ACCESS_CODE_REQUIRED && (
            <VStack spacing={4} w="100%">
              <Text fontWeight={"bold"} fontSize="xl">
                Please enter the access code for this event
              </Text>
              <OtpInput
                inputStyle={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  width: "60px",
                  borderWidth: "1px",
                  borderRadius: "5px",
                  height: "60px",
                  backgroundColor: "rgba(250,250,250,1.0)",
                  fontSize: "largest",
                }}
                inputType="tel"
                value={accessCode}
                onChange={setAccessCode}
                numInputs={4}
                renderSeparator={
                  <span style={{ color: "rgb(200,200,200)" }}>-</span>
                }
                renderInput={(props) => <Input {...props} />}
              />
              <Button
                onClick={() => validateAccessCode(event_id!, accessCode)}
                variant="kydDark"
                fontSize={"lg"}
                disabled={!accessCode || accessCode.length !== 4}
                w="100%"
                h="75px"
              >
                Submit
              </Button>
              <Button
                onClick={() => navigate(`/e/${event_id}`)}
                _hover={{ bg: "gray.300" }}
                w="100%"
                h="40px"
              >
                Go Back
              </Button>
            </VStack>
          )}
          {scannerState === ScannerState.VALIDATING_ACCESS_CODE && (
            <VStack spacing={4} w="100%">
              <Text fontWeight={"bold"} fontSize="xl">
                Validating access code please wait
              </Text>
              <Spinner />
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  const renderSignInPhoneNumberBody = (eId: string, aToken: string) => (
    <Modal
      isOpen={true}
      closeOnOverlayClick={false}
      onClose={() => {
        setModalType(ScannerModalType.INACTIVE);
        setPhoneNumber("");
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={3} my={5} pt={5}>
            <Text fontWeight={"black"}>Enter Phone Number</Text>
            <VStack w="100%" maxW={"300px"}>
              <Text>
                Enter the phone number the customer used to purchase their
                ticket(s)
              </Text>
              <PhoneInput
                disabled={scannerState === ScannerState.VALIDATING_ACCESS_CODE}
                autoFormat={true}
                inputProps={{
                  placeholder: "Mobile number",
                }}
                inputStyle={{
                  width: "100%",
                  height: "60px",
                  backgroundColor: "rgba(250,250,250,1.0)",
                  fontFamily: "Work sans",
                  borderWidth: "1px",
                  borderRadius: "5px",
                }}
                countryCodeEditable={false}
                country={"us"}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </VStack>
            <Button
              w="100%"
              h="50px"
              isDisabled={scannerState === ScannerState.PROCESSING}
              onClick={() => {
                setModalType(ScannerModalType.INACTIVE);
                setPhoneNumber("");
              }}
            >
              Cancel
            </Button>
            <Button
              w="100%"
              h="50px"
              bg="green.500"
              color="white"
              isDisabled={!phoneNumber || phoneNumber.length === 0}
              isLoading={scannerState === ScannerState.PROCESSING}
              onClick={() => {
                validateWalletToken(eId, aToken, phoneNumber, "phone");
                setModalType(ScannerModalType.INACTIVE);
              }}
            >
              Submit
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  const renderContent = () => (
    <Div100vh>
      <VStack overflow={"scroll"} bg="black" h="100%" w="100vw" p={5}>
        {accessToken &&
          event_id &&
          modalType > ScannerModalType.INACTIVE &&
          renderSignInPhoneNumberBody(event_id, accessToken)}
        {(!accessCode || !accessToken) && renderAccessCodeModal()}
        {accessToken && (
          <VStack color="white" w="100%" maxW={"md"}>
            <Text fontWeight={"bold"} fontSize="lg">
              {kydEvent?.name}
            </Text>
            <Text fontSize={"sm"}>
              {kydEvent?.display_start_at} @ {kydEvent?.venues[0].name}
            </Text>
            <Divider borderColor={"white"} />
            <VStack w="100%" p={6}>
              <VStack
                w="100%"
                justify={"center"}
                borderWidth={"5px"}
                borderColor="white"
                pos={"relative"}
                spacing={0}
                maxW="250px"
              >
                <VStack
                  w="100%"
                  pos={"absolute"}
                  zIndex={1000}
                  display={
                    scannerState === ScannerState.INITIALIZING
                      ? "inherit"
                      : "none"
                  }
                >
                  <Spinner size={"xl"} color="white" />
                  <Text color="white" fontWeight={"bold"}>
                    Loading scanner...
                  </Text>
                </VStack>

                {scannerState >= ScannerState.INVALID_TICKETS &&
                  renderInvalidTicketOverlay()}
                {(scannerState === ScannerState.VALIDATED ||
                  scannerState === ScannerState.CHECKING_IN) &&
                  renderValidTicketOverlay()}
                {scannerState === ScannerState.PROCESSING &&
                  renderProcessingTicketOverlay()}
                {scannerState === ScannerState.CHECK_IN_SUCCESS &&
                  renderCheckInSuccessOverlay()}

                <QrScanner
                  onDecode={setWalletToken}
                  onError={(error) => {
                    console.error(error);
                  }}
                  constraints={{ facingMode: "environment" }}
                  videoStyle={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  scanDelay={scannerState === ScannerState.READY ? 500 : 100000}
                />
              </VStack>
            </VStack>
            {scannerState === ScannerState.READY && renderReadyContent()}
            {(scannerState === ScannerState.VALIDATED ||
              scannerState === ScannerState.CHECKING_IN ||
              scannerState === ScannerState.CHECK_IN_SUCCESS ||
              scannerState === ScannerState.INVALID_TICKETS) &&
              renderValidTicketContent()}
          </VStack>
        )}
      </VStack>
    </Div100vh>
  );

  return renderContent();
}

export default KYDScanner;
