import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Text,
  Stack,
  Button,
  Checkbox,
  HStack,
  VStack,
  Box,
  Input,
  Icon,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent, KYDEvent } from "@common/interfaces/KYDEvent";
import { KYDCartV2 } from "@common/interfaces/KYDCart";

import { capitalized } from "../utils";
import { useFormContext } from "react-hook-form";

import {
  FaCcDiscover,
  FaCcAmex,
  FaCcVisa,
  FaCcMastercard,
  FaPlus,
  FaChild,
} from "react-icons/fa";
import {
  BillingAddress,
  CardHolder,
  KYDCardScheme,
} from "@common/interfaces/KYDUser";
import { CheckoutParams } from ".";
import { Select } from "chakra-react-select";
import {
  useStripe,
  useElements,
  PaymentElement,
  ExpressCheckoutElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useDialog } from "../Common/Dialog";
import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElementReadyEvent,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import Attendee from "./Attendee";

export type FormInputs = {
  first_name: string;
  last_name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  country: string;
  store_for_future_use: boolean;
  stored_cvv: string;
  payment_method_id: string | null;
};

function KYDCartDetail({
  kydEvent,
  cart,
  onExpressCheckout,
  paymentFormComplete,
  setPaymentFormComplete,
  stripeRef,
  elementsRef,
  radarRef,
}: {
  kydEvent: IKYDEvent;
  cart: KYDCartV2 | null;
  onExpressCheckout: () => void;
  paymentFormComplete: boolean;
  setPaymentFormComplete: (value: boolean) => void;
  stripeRef: React.MutableRefObject<Stripe>;
  elementsRef: React.MutableRefObject<StripeElements>;
  radarRef: React.MutableRefObject<any>;
}) {
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    | "loading"
    | StripeExpressCheckoutElementReadyEvent["availablePaymentMethods"]
  >("loading");
  const [paymentElementReady, setPaymentElementReady] =
    useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  stripeRef.current = stripe;
  elementsRef.current = elements;
  const {
    register,
    setValue: setFormValue,
    watch,
  } = useFormContext<FormInputs>();

  const watchPaymentMethodId = watch("payment_method_id");

  useEffect(() => {
    const loadRadarSession = async () => {
      const { radarSession: _radarSession } = await stripe.createRadarSession();
      radarRef.current = _radarSession;
    };
    if (stripe) {
      loadRadarSession();
    }
  }, [stripe]);

  const iconForScheme = (scheme: KYDCardScheme, props?: any) => {
    switch (scheme) {
      case "visa":
        return <Icon {...(props || {})} as={FaCcVisa} />;
      case "mastercard":
        return <Icon {...(props || {})} as={FaCcMastercard} />;
      case "amex":
        return <Icon {...(props || {})} as={FaCcAmex} />;
      case "discover":
        return <Icon {...(props || {})} as={FaCcDiscover} />;
      default:
        return <div></div>;
    }
  };

  const renderCompactBillingInformation = () => {
    if (cart.bypass_billing) {
      return null;
    }

    return (
      <VStack w="100%" spacing={4} textAlign={"left"}>
        {watchPaymentMethodId !== null &&
        cart &&
        cart.stored_payment_methods &&
        cart.stored_payment_methods.length > 0 ? (
          <Stack w="100%">
            <HStack>
              <Stack w={["100%"]} maxW={"300px"} spacing={0}>
                <Text fontSize={"sm"}>Saved Cards</Text>
                <Select
                  {...register("payment_method_id")}
                  isSearchable={false}
                  placeholder={null}
                  menuPortalTarget={document.body}
                  defaultValue={{
                    value: cart.stored_payment_methods[0].id,
                    label: (
                      <HStack>
                        {iconForScheme(cart.stored_payment_methods[0].scheme)}
                        <Text>
                          {capitalized(cart.stored_payment_methods[0].scheme)}
                        </Text>
                        <Text fontSize={"lg"}>••••</Text>
                        <Text>{cart.stored_payment_methods[0].last4}</Text>
                      </HStack>
                    ),
                  }}
                  onChange={(pm) => {
                    if (pm) {
                      setFormValue("payment_method_id", pm?.value);
                    }
                  }}
                  chakraStyles={{
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      background: "none",
                      w: "20px",
                    }),
                    indicatorSeparator: (provided, state) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                  options={cart.stored_payment_methods.map((pm) => {
                    return {
                      value: pm.id,
                      label: (
                        <HStack>
                          {iconForScheme(pm.scheme)}
                          <Text>{capitalized(pm.scheme)}</Text>
                          <Text fontSize={"lg"}>••••</Text>
                          <Text>{pm.last4}</Text>
                        </HStack>
                      ),
                    };
                  })}
                />
              </Stack>

              <Stack w={["100%"]} maxW={"100px"} spacing={0}>
                <Text fontSize={"sm"}>CVC *</Text>

                <Box
                  borderWidth={"1px"}
                  borderColor={"gray.200"}
                  rounded={"md"}
                  h="40px"
                  fontSize={"30px"}
                  pt={"10px"}
                  pl="15px"
                >
                  <CardCvcElement
                    options={{
                      placeholder: "CVC",
                      style: {
                        base: {
                          fontSize: "15px",
                          color: "#444",
                          fontFamily: "Arial",
                        },
                      },
                    }}
                    onChange={(e) => {
                      if (e.complete !== paymentFormComplete) {
                        setPaymentFormComplete(e.complete);
                      }
                    }}
                  />
                </Box>
              </Stack>
            </HStack>

            <HStack
              cursor={"pointer"}
              onClick={() => setFormValue("payment_method_id", null)}
            >
              <Icon color="blue.500" as={FaPlus} />
              <Text color="blue.500">Use a different card</Text>
            </HStack>
          </Stack>
        ) : null}

        {watchPaymentMethodId === null ? (
          <Stack w="100%" spacing={0}>
            {renderStripePaymentForm(kydEvent)}
          </Stack>
        ) : null}
      </VStack>
    );
  };

  const renderStripePaymentForm = (e: KYDEvent) => {
    return (
      <Stack w="100%">
        {paymentElementReady ? null : (
          <VStack w="100%">
            <Spinner />
          </VStack>
        )}
        <Stack display={paymentElementReady ? "inherit" : "none"}>
          <PaymentElement
            options={{
              defaultValues: {
                billingDetails: {
                  email: cart?.email,
                },
              },
              wallets: {
                applePay: "never",
                googlePay: "never",
              },
            }}
            onChange={(e) => {
              if (e.complete !== paymentFormComplete) {
                setPaymentFormComplete(e.complete);
              }
            }}
            onReady={() => {
              setPaymentElementReady(true);
            }}
          />
          <Stack spacing={0}>
            <Checkbox
              {...register("store_for_future_use")}
              isDisabled={
                cart
                  ? cart.stored_payment_methods &&
                    cart.stored_payment_methods.length >= 5
                  : true
              }
            >
              Save card for later
            </Checkbox>
            {cart &&
            cart.stored_payment_methods &&
            cart.stored_payment_methods.length >= 5 ? (
              <Text fontSize={"sm"} color="gray.500">
                {"(Max 5 card limit reached)"}
              </Text>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const renderExpressCheckout = () => {
    if (cart && cart.bypass_billing) {
      return null;
    }

    return (
      <VStack
        display={
          availablePaymentMethods === "loading" || !availablePaymentMethods
            ? "none"
            : "inherit"
        }
        w={{ base: "100%", md: "75%" }}
      >
        <VStack spacing={"-3"}>
          <Box px={4} zIndex={2} bg="white">
            <Text fontSize={"sm"} fontWeight={"medium"} color="gray.800">
              Express Checkout
            </Text>
          </Box>
          <Stack
            zIndex={1}
            borderWidth={"1px"}
            borderColor={"gray.100"}
            rounded={"lg"}
            p={4}
            w="100%"
          >
            <ExpressCheckoutElement
              options={{
                paymentMethodOrder: ["apple_pay", "google_pay"],
              }}
              onReady={(e) => {
                setAvailablePaymentMethods(e.availablePaymentMethods);
                return {};
              }}
              onClick={({ resolve }) => {
                const options = {
                  emailRequired: true,
                  phoneNumberRequired: true,
                };
                resolve(options);
              }}
              onConfirm={(event) => {
                const email = event.billingDetails?.email;
                setFormValue("email", email);

                if (event.billingDetails?.name) {
                  const parts = event.billingDetails?.name.split(" ");
                  for (let i = 0; i < parts.length; i++) {
                    if (i === 0) {
                      setFormValue("first_name", parts[i]);
                    } else if (i === parts.length - 1) {
                      setFormValue("last_name", parts[i]);
                    }
                  }
                }
                onExpressCheckout();
              }}
            />
          </Stack>
        </VStack>
        <VStack spacing={"-3"} w={{ base: "100%", md: "75%" }}>
          <Box px={4} zIndex={2} bg="white">
            <Text fontSize={"sm"} fontWeight={"medium"} color="gray.500">
              OR
            </Text>
          </Box>
          <Divider />
        </VStack>
      </VStack>
    );
  };

  return (
    <VStack spacing={4} w="100%" p={{ base: "2", md: "0" }}>
      {renderExpressCheckout()}
      <Attendee kydEvent={kydEvent} cart={cart} />
      <Divider my={4} />
      {renderCompactBillingInformation()}
    </VStack>
  );
}

export default KYDCartDetail;
