import React, { useEffect, useCallback, useState } from "react";
import "./App.css";
import awsconfig from "./aws-exports";
import * as AWSAmplify from "aws-amplify";
import { Routes, Route, Navigate } from "react-router-dom";
import KYDEvent from "./KYDEvent";
import Scanner from "./Scanner";
import Bio from "./Bio";
import Embed from "./Embed";
import {
  analytics,
  AUTH_STATES,
  loadGoogleMapsAPI,
  ModalType,
  PIXELS,
  setupIntercom,
} from "./utils";
import { CognitoUser } from "amazon-cognito-identity-js";
import { HubCapsule } from "@aws-amplify/core";
import KYDModal from "./Modal";
import { Wallet } from "@common/interfaces/KYDUser";
import Dialog from "./Common/Dialog";

const { Amplify, Hub, Auth } = AWSAmplify;

Amplify.configure(awsconfig);

function App() {
  const [authState, setAuthState] = useState(AUTH_STATES.LOADING);
  const [currentUser, setUser] = useState<CognitoUser | null>(null);
  const [modalType, setModalType] = useState(ModalType.INACTIVE);
  const [modalContent, setModalContent] = useState<string | null>("");

  const [wallet, setWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    const setupAnalytics = async () => {
      await analytics.init();
    };

    setupAnalytics();

    setupIntercom();
  }, []);

  const setModal = (modalType: ModalType, content?: string) => {
    setModalType(modalType);
    setModalContent(content || null);
  };

  const fetchAuthState = useCallback(async () => {
    console.log("Fetch auth state");
    try {
      const res = await Auth.currentAuthenticatedUser();
      if (res as CognitoUser) {
        setAuthState(AUTH_STATES.AUTHENTICATED);
        setUser(res);

        const session = (res as CognitoUser).getSignInUserSession();
        if (session) {
          const jwtToken = session?.getIdToken().decodePayload();

          console.log(jwtToken);
          if (jwtToken?.sub) {
            console.log("Set User: ", jwtToken.sub);
            const email = jwtToken?.email;
            analytics.setUser(jwtToken!.sub, email);
          }
        }
        analytics.t("auth: success");
      } else {
        setAuthState(AUTH_STATES.NEEDS_AUTH);
        console.log("Not authed");
        setUser(null);
      }
    } catch (err) {
      setAuthState(AUTH_STATES.NEEDS_AUTH);
      console.log("failed auth: ", err);
      setUser(null);
    }
  }, []);

  const onAuthAction = useCallback(
    (data: HubCapsule) => {
      const { payload } = data;
      const { event } = payload;
      if (event === "signIn" || event === "signOut") {
        console.log("Auth Event: ", event);
        fetchAuthState();
      }
    },
    [fetchAuthState]
  );

  useEffect(() => {
    if (currentUser) {
      currentUser.getUserAttributes((err, results) => {
        if (!err && results && results.length > 0) {
          const email = results.find((result) => result.Name === "email");
          const sub = results.find((result) => result.Name === "sub");

          PIXELS.identify({
            user_id: sub ? sub.Value : null,
            email: email ? email.Value : null,
            phone_number: currentUser.getUsername(),
          });
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    Hub.listen("auth", onAuthAction);
  }, [onAuthAction]);

  useEffect(() => {
    fetchAuthState();
  }, [fetchAuthState]);

  return (
    <div className="App">
      <Dialog />
      {modalType > ModalType.INACTIVE && (
        <KYDModal
          modalType={modalType}
          setModalType={setModalType}
          wallet={wallet}
          setWallet={setWallet}
          content={modalContent}
          setContent={setModalContent}
        />
      )}
      <Routes>
        <Route path="embed/*" element={<Embed />} />
        <Route path="e">
          <Route
            path=":event_id/*"
            element={
              <KYDEvent
                modalType={modalType}
                setModalType={setModal}
                authState={authState}
                currentUser={currentUser}
                setWallet={setWallet}
                wallet={wallet}
              />
            }
          />
        </Route>
        <Route path="bio" element={<Bio />} />
        <Route path="scanner">
          <Route path=":event_id" element={<Scanner />} />
        </Route>
        <Route path="/" element={<Navigate replace to="/bio" />} />
      </Routes>
    </div>
  );
}

export default App;
