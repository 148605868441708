import { useRef, useState } from "react";
import {
  Text,
  Stack,
  Icon,
  Button,
  VStack,
  Box,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent } from "@common/interfaces/KYDEvent";
import { KYDTicketType } from "@common/interfaces/KYDTicket";

import TicketTypes from "./TicketTypes";

import { ModalType } from "../utils";
import { FaCartPlus, FaBell } from "react-icons/fa";
import { Wallet } from "@common/interfaces/KYDUser";
import { KYDCart, KYDCartV2 } from "@common/interfaces/KYDCart";
import FloatingBottomPanel from "./FloatingBottomPanel";
import RemindMeOnSale from "./RemindMeOnSale";
import dayjs from "dayjs";
import InsertCode from "./InsertCode";

function KYDEventDetail({
  kydEvent,
  draftCart,
  cart,
  wallet,
  onAddToCart,
  onRemindMe,
  onUpdateTicketTypeQuantity,
  modalType,
  loading,
  onRemindMeWhenOnSale,
  onGoToTicketCode,
  code,
}: {
  kydEvent: IKYDEvent;
  wallet: Wallet | null;
  draftCart?: { id: string; total: number; ticket_types?: object };
  loading: Record<string, boolean>;
  onRemindMeWhenOnSale: (kydEventId: string) => void;
  cart: KYDCart | KYDCartV2 | null;
  onAddToCart: () => void;
  onRemindMe: () => void;
  onUpdateTicketTypeQuantity: (
    ticket_type: KYDTicketType,
    action: string
  ) => void;
  code?: string;
  modalType: ModalType;
  onGoToTicketCode: (code: string) => void;
}) {
  const insertCodeRef = useRef<HTMLInputElement | null>();
  const [internalCode, setCode] = useState(code);
  const [codeDisplayMode, setCodeDisplayMode] = useState<"closed" | "open">(
    internalCode && internalCode.length > 0 ? "open" : "closed"
  );
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get("utm_source");

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const isBuyButtonFloating = draftCart.total > 0;
  const showReminder =
    kydEvent.display_options.show_on_sale_reminder &&
    kydEvent.ticket_types.length === 0;

  return (
    <Box
      w="100%"
      roundedTop={{ base: "none", md: "lg" }}
      bg={{ base: "none", md: "white" }}
      px={[2, null, 5]}
      pt={[2, null, 5]}
      pb={4}
    >
      <Stack
        w="100%"
        pt={2}
        spacing={{ base: 0, md: 4 }}
        borderTop={{ base: "1px", md: "none" }}
        borderTopColor={"white"}
      >
        <Stack spacing={0}>
          <HStack width={"full"} justifyContent={"space-between"} spacing={0}>
            <Stack spacing={0}>
              <Text
                display={{ base: "none", md: "table-cell" }}
                w="100%"
                textAlign={"left"}
                fontWeight={"bold"}
                fontSize="xl"
              >
                {kydEvent.display_mode === "rsvp"
                  ? "Register"
                  : "Get Your Tickets"}
              </Text>
              {kydEvent.display_mode === "normal" &&
              kydEvent.display_options?.price_mode === "inclusive" ? (
                <Text
                  display={{ base: "none", md: "flex" }}
                  fontSize={"sm"}
                  textAlign={"left"}
                >
                  All-In Pricing. No surprises later.
                </Text>
              ) : null}
              {kydEvent.display_mode === "rsvp" &&
              (!kydEvent.display_options ||
                !kydEvent.display_options.hide_event_subheader) ? (
                <Text
                  color={{ base: "white", md: "gray.700" }}
                  maxW={"500px"}
                  fontSize={"sm"}
                >
                  RSVP with your email and get notified when tickets go on sale
                  for the next event, and get direct updates from{" "}
                  {kydEvent.organization
                    ? kydEvent.organization.name
                    : " this host"}
                  .
                </Text>
              ) : null}
            </Stack>
            {isMobile ? null : (
              <InsertCode
                loading={loading.code}
                focus={!!code}
                ref={insertCodeRef}
                setCode={setCode}
                setCodeDisplayMode={setCodeDisplayMode}
                internalCode={internalCode}
                onGoToTicketCode={onGoToTicketCode}
                codeDisplayMode={codeDisplayMode}
              />
            )}
          </HStack>
        </Stack>
        <RemindMeOnSale
          isLoading={loading.reminder}
          onSaleDate={dayjs(
            kydEvent.on_sale_at_override || kydEvent.on_sale_at
          ).tz(kydEvent.timezone)}
          onRemindMe={() => onRemindMeWhenOnSale(kydEvent.id)}
          show={showReminder}
          hasReminder={wallet?.has_reminder}
        />
        {kydEvent.ticket_types.length === 0 && !showReminder ? (
          <VStack color={{ base: "white", md: "black" }} w="100%" py={4}>
            <Text>Please enter a presale code to view available tickets</Text>

            {isMobile ? null : (
              <Button
                bg={{ base: "white", md: "black" }}
                color={{ base: "black", md: "white" }}
                _hover={{ bg: { base: "gray.100", md: "gray.700" } }}
                onClick={() => {
                  setCodeDisplayMode("open");
                  if (insertCodeRef.current) {
                    insertCodeRef.current.focus();
                  }
                }}
              >
                Enter code
              </Button>
            )}
          </VStack>
        ) : null}
        {isMobile ? (
          <InsertCode
            loading={loading.code}
            focus={!!code}
            setCode={setCode}
            setCodeDisplayMode={setCodeDisplayMode}
            internalCode={internalCode}
            onGoToTicketCode={onGoToTicketCode}
            codeDisplayMode={codeDisplayMode}
          />
        ) : null}
        {kydEvent.ticket_types.length > 0 && (
          <VStack w="100%" spacing={1}>
            <TicketTypes
              wallet={wallet}
              kydEvent={kydEvent}
              cart={cart}
              quantities={draftCart.ticket_types || {}}
              ticketTypes={kydEvent.ticket_types}
              updateQuantity={onUpdateTicketTypeQuantity}
            />
            {utm_source === "snapchat" && draftCart.total === 0 ? (
              <Button
                w="100%"
                maxW={"lg"}
                bg={{ base: "green.500", md: "green.500" }}
                color={{ base: "white", md: "white" }}
                _hover={{ bg: { base: "gray.100", md: "gray.700" } }}
                isLoading={modalType === ModalType.SECURING_TICKETS}
                onClick={onRemindMe}
                h="50px"
                rightIcon={
                  kydEvent.display_mode === "normal" ? (
                    <Icon color={{ base: "white", md: "white" }} as={FaBell} />
                  ) : undefined
                }
              >
                Remind Me
              </Button>
            ) : (
              <FloatingBottomPanel isFloating={isBuyButtonFloating}>
                <Button
                  width={"100%"}
                  bg={{ base: "white", md: "black" }}
                  color={{ base: "black", md: "white" }}
                  _hover={{ bg: { base: "gray.100", md: "gray.700" } }}
                  isLoading={modalType === ModalType.SECURING_TICKETS}
                  isDisabled={draftCart.total === 0}
                  onClick={onAddToCart}
                  h="50px"
                  rightIcon={
                    kydEvent.display_mode === "normal" ? (
                      <Icon
                        color={{ base: "black", md: "white" }}
                        as={FaCartPlus}
                      />
                    ) : undefined
                  }
                >
                  {kydEvent.display_mode === "rsvp"
                    ? "RSVP Now"
                    : `Get${
                        draftCart.total > 0 ? ` ${draftCart.total}` : ""
                      } Ticket${
                        draftCart.total > 1 || !draftCart.total ? "s" : ""
                      }`}
                </Button>
              </FloatingBottomPanel>
            )}
          </VStack>
        )}
      </Stack>
    </Box>
  );
}

export default KYDEventDetail;
